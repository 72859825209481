<template>
    <div class="binding-page">
      <header>
        <van-nav-bar
          @click-left="onClickLeft"
        >
          <template #left>
            <div>
              <van-icon name="arrow-left" color="#000" size="24" />
              <span class="back-title">一键绑定</span>
            </div>
          </template>
        </van-nav-bar>
      </header>
      <main v-if="checkList && checkList.length">
        <div class="main-header">
          <div class="main-header-left">未绑定完成剧本：{{checkList.length}}</div>
          <div class="main-header-right" @click="checkAll">全选</div>
        </div>
        <van-checkbox-group v-model="checked" ref="checkboxGroup" >
          <div class="item-wrap" v-for="item in checkList" :key="item.id">
            <div class="check-wrap-left" >
              <img :src="`${configUrl.imgRoot}${item.dramaImgUrl}`" alt="">
              <div>
                <div class="item-title">{{item.dramaName}}</div>
                <div class="item-status">未绑定完成</div>
              </div>
            </div>
            <van-checkbox checked-color="#1D1DD8" :name="item.id" > </van-checkbox>
          </div>
        </van-checkbox-group>
      </main>
      <footer v-if="checkList && checkList.length">
          <van-button @click="submit" color="#1D1DD8" size="normal" block>保存</van-button>
      </footer>
    </div>
  </template>
  <script>
  import { ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { getDramaNoBindAPi, OneClickBindingApi } from '@request/api'
  import { Notify } from 'vant'
  // import store from '@/store'
  import config from '@/config.js'
  export default {
    data(){
      return {
        configUrl: config
      }
    },
    setup(){
      const checked = ref([])
      const checkboxGroup = ref(null)
      const checkList = ref([])
      const checkAll = () => {
        checkboxGroup.value.toggleAll(true);
      }
      const toggleAll = () => {
        checkboxGroup.value.toggleAll();
      }
      // const userChange = (event) => {
      //   console.log(event)
      // }
      const router = useRouter()
      const onClickLeft = () => {
          router.back()
      }
      const submit = () => {
        if (!checked.value.length) {
          Notify({ type: 'warning', message: '请勾选剧本！' })
          return
        }
        const params = {
          storeId: localStorage.getItem('storeId'),
          dramaIds: [...checked.value]
        }
        OneClickBindingApi(params).then(res => {
          if (res.success) {
            Notify({ type: 'success', message: '角色绑定成功！' })
            router.back()
          } else {
            Notify({ type: 'danger', message: res.msg })
          }
        })
      }
      onMounted(() => {
        if(localStorage.getItem('storeId')){
          getDramaNoBindAPi(localStorage.getItem('storeId'), 1).then(res => {
            if (res.success) {
              checkList.value = res.data
            }
          })
        }
      })
      return {
        onClickLeft,
        submit,
        checked,
        checkAll,
        toggleAll,
        checkboxGroup,
        checkList
      }
    }
  }
  </script>
  <style lang="less" scoped>
  .binding-page {
    position: relative;
    background: #eee;
    /deep/ .van-tree-select__content{
      flex: 1;
    }
  }
  header {
    /deep/ .van-nav-bar {
      background:transparent;
    }
    .back-title {
      margin-left: 10px;
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      line-height: 29px;
    }
  }
  .main-header {
    padding: 0 0 10px;
    display: flex;
    justify-content: space-between;
    .main-header-left {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 20px;
    }
    .main-header-right {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D1DD8;
      line-height: 22px;
    }
  }
  main {
    padding: 0 16px;
      .item-wrap {
          margin-bottom: 16px;
          background: #fff;
          padding: 16px;
          border-radius: 8px;
      }
  }
  .item-wrap {
    display: flex;
    justify-content: space-between;
    .check-wrap-left {
      display: flex;
      align-items: center;
      .item-title {
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 29px;
      }
      .item-status {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #E02020;
        line-height: 20px;
      }
      img {
        margin-right: 16px;
        display: block;
        width: 75px;
        height: 120px;
        border-radius: 4px;
      }
    }
  }
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #666;
  }
  .action {
      font-size: 14px;
      color: #1989fa;
      font-weight: normal;
  }
  .header-title, .item-title {
      font-size: 14px;
      font-weight: bold;
      color: #666;
  }
  main {
  }
  .QR-code {
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      color: #666;
      border-bottom: 1px solid #eee;
      img{
          margin-bottom:5px;
          width: 150px;
          height: 150px;

      }
  }
  .item-footer{
      padding: 5px 0;
      display: flex;
      align-items: center;
  }
  .user-logo {
      width: 40px;
      height: 40px;
      border-radius: 100%;
  }
  .user-wrap {
      text-align: center;
      font-size: 12px;
      color: #1989fa;
  }
  footer{
      padding:16px;
      /deep/ .van-button {
        border-radius: 8px;
      }
      /deep/ .van-button__text {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
  }
  .check-wrap {
      padding: 10px 15px;
      /deep/ .van-checkbox {
          margin-bottom: 10px;
      }
  }
  .control {
      margin-top: 10px;
  }
  </style>
