import axios from './http'
import qs from 'qs'
const basUrl = '/api'
const api = {
    getOpenId: `${basUrl}/getOpenId`, // 获取openIdbindStore
    bindStore:  `${basUrl}/auth/userStoreRel/save`, // 商户绑定
    bindRoleStore:  `${basUrl}/userRoleRel/save`, // 店员绑定
    bindRoleDeleteStore:  `${basUrl}/userRoleRel/delete`, // 店员解除绑定
    queryByLogin:  `${basUrl}/getLoginUser`, // 获取用户权限
    queryPageStore:  `${basUrl}/auth/userStoreRel/queryPage`, // 门店查询
    queryAccount: `${basUrl}/financialAccount/queryOne`, // 账户查询
    financialFlow: `${basUrl}/financial/financialFlow/queryPage`, // 资金流水
    transferToBase: `${basUrl}/financialAccount/transferToBase`, // 转入开本账户
    queryGlass: `${basUrl}/glass/queryPage`, // 查询眼镜列表
    glassSave: `${basUrl}/glass/save`, // 新增眼镜
    updateGlass: `${basUrl}/glass/update`, // 新增眼镜
    queryDramaPage: `${basUrl}/drama/queryPage`, // 剧本查询
    glassRoleRel: `${basUrl}/glassRoleRel/save`, // 角色眼镜绑定关系
    glassRoleRelUpdate: `${basUrl}/glassRoleRel/update`, // 角色眼镜绑定关系
    menuQueryAll: `${basUrl}/auth/menu/queryAll`, // 菜单列表
    userRoleSave: `${basUrl}/userRole/save`, // 子账号新增
    userRoleUpdate: `${basUrl}/userRole/update`, // 子账号新增
    userRoleQueryAll: `${basUrl}/userRole/queryAll`, // 子账号列表查询
    activityQueryPage: `${basUrl}/activity/queryPage`, // 子账号列表查询
    activityQueryOne: `${basUrl}/userRole/queryOne`, // 查询子账号详情
    activityBegin: `${basUrl}/activity/begin`, // 发车接口
    activityEnd: `${basUrl}/activity/end`, // 结束接口
    amountQuery: `${basUrl}/store/findOne`, // 账户余额
    generateQrcodeByParam: `${basUrl}/generateQrcodeByParam/qrcode`, // 根据商户ID生成二维码
    generateWxPrePayOrder: `${basUrl}/generateWxPrePayOrder/prePay`, // 支付接口
    getByDateUsefulDetail: `${basUrl}/activity/getByDateUsefulDetail`, // 开本数据统计
    getByDateUsefulInfo:`${basUrl}/activity/getUsefulStaticsDetailByStoreId`, // 最近开本
    getDramaNoBind: `${basUrl}/glassRoleRel/getDramaNoBind`, // 获取所有未绑定角色的剧本
    OneClickBinding: `${basUrl}/glassRoleRel/OneClickBinding`, // 一键绑定
    glassRoleRelDel: `${basUrl}/glassRoleRel/`, // 删除眼镜和角色绑定关系
    cancelActive: `${basUrl}/activity/cancelActive`, // 取消发车
    getDramaActiveInfo: `${basUrl}/activity/getDramaActiveInfo`, // 取消发车
    getNoBindDrama: `${basUrl}/glass/getNoBindDrama`, // 获取该眼镜未绑定的剧本列表
    queryStoreDramaPage: `${basUrl}/activity/queryStoreDramaPage`, // 获取剧本列表
    getDramaActiveInfoByStoreId: `${basUrl}/activity/getDramaActiveInfoByStoreId`,  // 根据剧本id、店铺id查询活动具体详情
    getDramaDetail: `${basUrl}/drama/findOne`, // 获取剧本详情
    getDramaPrice: `${basUrl}/drama/findPrice`, // 获取剧本价格
    addPlayerInfo: `${basUrl}/activityPlayer/testSave`, // 测试环境-自动新增6个活动玩家信息
    getInfoByStoreDrama:`${basUrl}/storeDrama/getInfoByStoreDrama`
}
// 获取openID
export function getOpenIdApi (data) {
    return axios.post(
        api.getOpenId,
        qs.stringify(data),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded' //将表单数据传递转化为form-data类型
            }
        }
    )
}

// 商户绑定
export function bindStore (data) {
    return axios.post(api.bindStore, data)
}
// 账户余额
export function getAmount (id) {
    return axios.post(`${api.amountQuery}/${id}`)
}
// 店员绑定
export function bindRoleStore (data) {
    return axios.post(api.bindRoleStore, data)
}
// 解除绑定
export function bindRoleDeleteStore (id) {
    return axios.post(`${api.bindRoleDeleteStore}/${id}`)
}

// 查询门店
export function queryPageStore () {
    return axios.post(api.queryPageStore, { pageSize: 100 })
}
// 查询菜单权限
export function queryByLogin (data) {
    return axios.post(api.queryByLogin, data)
}

// 查询开本账户
export function queryAccount (data) {
    return axios.post(api.queryAccount, data)
}

// 资金流水 开本，分成
export function financialFlow (data) {
    return axios.post(api.financialFlow, data)
}
// 转入开本账户
export function transferToBase (data) {
    return axios.post(api.transferToBase, data)
}
// 眼镜列表
export function queryGlass (data) {
    return axios.post(api.queryGlass, data)
}
// 新增眼镜
export function glassSave (data) {
    return axios.post(api.glassSave, data)
}

// 修改眼镜
export function updateGlass (data) {
    return axios.post(api.updateGlass, data)
}

// 剧本查询
export function queryDramaPage (data) {
    return axios.post(api.queryDramaPage, data)
}

// 角色眼镜绑定关系
export function glassRoleRel (data) {
    return axios.post(api.glassRoleRel, data)
}

// 修改绑定关系
export function glassRoleRelUpdate (data) {
    return axios.post(api.glassRoleRelUpdate, data)
}


// 菜单列表
export function menuQueryAll () {
    return axios.post(api.menuQueryAll, {})
}

// 子账号新增
export function userRoleSave (data) {
    return axios.post(api.userRoleSave, data)
}
// 子账号删除
export function userRoleUpdate (data) {
    return axios.post(api.userRoleUpdate, data)
}

// 子账号查询
export function userRoleQueryAll (data) {
    return axios.post(api.userRoleQueryAll, data)
}

// 剧本活动列表
export function activityQueryPage (data) {
    return axios.post(api.activityQueryPage, data)
}

// 发车
export function activityBegin (data) {
    return axios.post(api.activityBegin, data)
}

// 结束
export function activityEnd (data) {
    return axios.post(api.activityEnd, data)
}
// 查询子账号详情
export function activityQueryOne (data) {
    return axios.post(api.activityQueryOne, data)
}
// 根据商户ID生成二维码
export function generateQrcodeByParam (data) {
    return axios.post(api.generateQrcodeByParam, data)
}
// 充值接口
export function generateWxPrePayOrder (data) {
    return axios.post(api.generateWxPrePayOrder, data)
}
// 开本统计数据
export function getByDateUsefulDetailApi(startTime, endTime, storeId) {
    return axios.get(`${api.getByDateUsefulDetail}?storeId=${storeId}&startTime=${startTime}&endTime=${endTime}`)
}
// 获取所有未绑定角色的剧本
export function getDramaNoBindAPi (storeId, status) {
    return axios.get(`${api.getDramaNoBind}?storeId=${storeId}&status=${status}`)
}
// 一键绑定
export function OneClickBindingApi (data) {
    return axios.post(api.OneClickBinding, data)
}
// 删除绑定
export function glassRoleRelDelApi (id) {
    return axios.delete(`${api.glassRoleRelDel}${id}`)
}
// 取消发车
export function cancelActiveApi (id) {
    return axios.post(`${api.cancelActive}?activeId=${id}`)
}
// 获取所有未绑定角色的剧本
export function getDramaActiveInfoApi (activeId) {
    return axios.get(`${api.getDramaActiveInfo}?activeId=${activeId}`)
}
// 获取所有未绑定角色的剧本
export function getNoBindDrama (machineId) {
    return axios.get(`${api.getNoBindDrama}?machineId=${machineId}`)
}
// 获取剧本列表
export function queryStoreDramaPage (data) {
    return axios.post(api.queryStoreDramaPage, data)
}
// 获取最近开本
export function getByDateUsefulDetail(storeId) {
    return axios.get(`${api.getByDateUsefulInfo}?storeId=${storeId}`)
}
// 获取活动详情
export function getDramaActiveInfoByStoreId(dramaId, storeId) {
    return axios.get(`${api.getDramaActiveInfoByStoreId}?dramaId=${dramaId}&storeId=${storeId}`)
}

// 获取剧本详情
export function getDramaDetail(dramaId) {
    return axios.get(`${api.getDramaDetail}/${dramaId}`)
}
// 获取价格详情
export function getDramaPrice(dramaId, storeId) {
    return axios.get(`${api.getDramaPrice}/${dramaId}/${storeId}`)
}

// 获取剧本列表
export function addPlayerInfo (data) {
    return axios.post(api.addPlayerInfo, data)
}

// 获取剧本分成
export function getInfoByStoreDrama (data) {
    return axios.post(api.getInfoByStoreDrama, data)
}



