<style lang="less" scoped>

.unbinding-con {
  padding: 32px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .unbinding-tip {
    margin-bottom: 2px;
    height: 29px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 29px;
  }
}
.unbinding-footer {
  padding: 0px 16px 24px;
  display: flex;
  justify-content: space-between;
  /deep/ .van-button {
    width: 136px;
    height: 44px;
    background: #EBEBEB;
    border-radius: 8px;
  }
  .cancel {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
  }
}
  .activity-page {
    overflow: auto;
    background: rgb(238, 238, 238);
  }
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
.action {
    font-size: 14px;
    color: #1989fa;
    font-weight: normal;
}
main {
  padding: 20px 16px 32px;
    .item-follow {
      margin-bottom: 16px;
      background: #fff;
      border-radius: 8px;
    }
    .item-wrap-prepare {
      margin-bottom: 16px;
      padding: 16px;
      border: 1px solid #1D1DD8;
      border-radius: 8px;
    }
}
.prepare-header {
  display: flex;
  img {
    margin-right: 16px;
    width: 75px;
    height: 120px;
    border-radius: 4px;
  }
  .prepare-header-right {
    flex: 1;
    .status {
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D1DD8;
      line-height: 20px;
    }
    .prepare-title {
      height: 29px;
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      line-height: 29px;
    }
    .prepare-tip {
      padding-bottom: 9px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 20px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .prepare-time {
      margin-top: 9px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 20px;
    }
  }
}
.in-num-wrap {
  padding: 4px 0;
  text-align: right;
  .in-num-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    line-height: 20px;
  }
  .in-num {
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D1DD8;
    line-height: 29px;
  }
}
.prepare-main{
  display: flex;
  flex-wrap: wrap;
  .user-wrap {
    margin-left: 9px;
    &:nth-child(3n+1) {
      margin-left: 0;
    }
    margin-bottom: 12px;
    padding: 8px 12px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97px;
    height: 132px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    box-sizing: border-box;
    .user-title {
      margin-bottom: 4px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.25);
      line-height: 16px;
    }
    .user-name {
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      line-height: 18px;
      text-align: center;
      width: 72px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .user-role {
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D1DD8;
      line-height: 16px;
      text-align: center;
      width: 72px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      margin-bottom: 4px;
      width: 54px;
      height: 54px;
      border-radius: 100%;
    }
  }
}
.prepare-footer {
  display: flex;
  padding: 16px 0 0;
  .btn-left {
    width: 77px;
    height: 40px;
    border-radius: 4px;
    color: rgba(0,0,0,0.5);
    border: 1px solid rgba(0,0,0,0.5);
  }
  .btn-right {
    flex: 1;
    margin-left: 12px;
    height: 40px;
    background: #1D1DD8;
    border-radius: 4px;
  }
}
.follow-header {
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  .follow-num{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
  }
  .follow-status {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D1DD8;
  }
}
.follow-main {
  padding: 12px 16px 16px;
}
.follow-footer {
  padding: 0 16px 16px;
  text-align: right;
  .follow-btn {
    width: 77px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.5);
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
  }
}
.header {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    align-items: center;
}
.main {
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
}
.main div{
  line-height: 30px;
}
.footer {
    padding-top: 10px;
    text-align: right;
}
header {
  height: 120px;
  padding: 16px 0;
  background: url('./img/header-bg.png');
  background-size:cover;
  box-sizing: border-box;
  /deep/ .van-nav-bar {
    background:transparent;
  }
  /deep/ .van-hairline--bottom:after {
    border: none;
  }
  .page-title {
    margin-top: 9px;
    padding-left: 16px;
    color: #fff;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 33px;
  }
}
/deep/ .van-tabs__nav {
  background:transparent;
}
/deep/ .van-dialog__header {
  padding: 0;
}
.unbinding-con {
  padding: 32px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .unbinding-tip {
    margin-bottom: 2px;
    height: 29px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 29px;
  }
}
.unbinding-footer {
  padding: 0px 16px 24px;
  display: flex;
  justify-content: space-between;
  /deep/ .van-button {
    width: 136px;
    height: 44px;
    background: #EBEBEB;
    border-radius: 8px;
  }
  .cancel {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
  }
}
</style>
<template>
  <div class="activity-page ">
    <header>
      <van-nav-bar
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" color="#fff" :size="32" />
        </template>
      </van-nav-bar>
      <div class="page-title">剧本杀活动管理</div>
    </header>
    <van-tabs v-model:active="active" color="#1D1DD8" title-active-color="#1D1DD8" >
      <van-tab title="全部"></van-tab>
      <van-tab title="未发车"></van-tab>
      <van-tab title="发车中"></van-tab>
      <van-tab title="已结束"></van-tab>
    </van-tabs>
    <main v-if="activityList && activityList.length">
      <div v-for="(item, index) in activityList" :key="index">
        <div v-if="item.status === 0" class="item-wrap-prepare">
          <section class="prepare-header">
            <img :src="`${configUrl.imgRoot}${item.dramaImg}`" alt="">
            <div class="prepare-header-right">
              <div class="status">{{getStatus(item.status)}}</div>
              <div class="prepare-title">{{item.dramaName}}</div>
              <div class="prepare-tip">需要玩家数 {{item.roleNum}}人</div>
              <div class="prepare-time">开本时间 {{item.createTime}}</div>
            </div>
          </section>
          <section class="in-num-wrap">
            <span class="in-num-title">已加入人数</span> <span class="in-num">{{item.joinNum > item.roleNum ? item.roleNum: item.joinNum}}/{{item.roleNum}}</span>
          </section>
          <section class="prepare-main">
            <template v-for="(roleUser, roleIndex) in item.roleUserInfoVoList" :key="roleIndex">
              <div class="user-wrap" v-if="roleIndex < item.roleNum" >
                <div class="user-title">{{roleUser.machineNum}}号设备</div>
                <img :src="roleUser.headImg" alt="">
                <div class="user-name">{{roleUser.nickName}}</div>
                <div class="user-role">{{roleUser.roleName}}</div>
              </div>
            </template>
          </section>
          <section class="prepare-footer">
            <van-button class="btn-left" @click="cancelDia(item.activeId)" >取消</van-button>
            <van-button :disabled="accout.cooperativeState!==0" class="btn-right" @click="begin(item.activeId)" color="#1D1DD8" >发车</van-button>
          </section>
        </div>
        <div class="item-follow" v-if="item.status === 1 || item.status === 2">
          <div class="follow-header">
            <div class="follow-num">发车单号：{{item.orderNo}}</div>
            <div class="follow-status" v-if="item.status === 1" >{{getStatus(item.status)}}</div>
            <div class="follow-status" style="color: rgba(0,0,0,0.5);" v-else>{{getStatus(item.status)}}</div>
          </div>
          <section class="prepare-header follow-main" @click="toDetail(item)">
            <img :src="`${configUrl.imgRoot}${item.dramaImg}`" alt="">
            <div class="prepare-header-right">
              <div class="prepare-title">{{item.dramaName}}</div>
              <div class="prepare-tip">玩家数 {{item.roleNum}}人</div>
              <div class="prepare-time">发车时间 {{item.beginTime}}</div>
            </div>
          </section>
          <section class="follow-footer">
            <van-button class="follow-btn" @click="closeFn(item.activeId)" v-if="item.status === 1" >结束</van-button>
          </section>
        </div>
      </div>
    </main>
    <van-empty v-else image="error" description="暂无数据" />
    <van-dialog v-model:show="closeCancel" :show-cancel-button="false">
      <template #title>
      </template>
      <div class="unbinding-con">
        <div class="unbinding-tip">是否取消发车！</div>
      </div>
      <template #footer>
        <div class="unbinding-footer">
          <van-button class="cancel" @click="closeCancel = false" color="#EBEBEB ">取消</van-button>
          <van-button class="submit" :loading="cancelLoading" @click="cancelActiveFn" color="#20B2E0" >确认</van-button>
        </div>
      </template>
    </van-dialog>
    <van-dialog v-model:show="closeDia" :show-cancel-button="false">
        <template #title>
        </template>
        <div class="unbinding-con">
          <div class="unbinding-tip">是否结束该剧本！</div>
        </div>
        <template #footer>
          <div class="unbinding-footer">
            <van-button class="cancel" @click="closeDia = false" color="#EBEBEB ">取消</van-button>
            <van-button class="submit" @click="end" color="#1D1DD8" >确认</van-button>
          </div>
        </template>
      </van-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { activityQueryPage, activityBegin, activityEnd, cancelActiveApi, getAmount } from '@request/api'
import { onMounted } from '@vue/runtime-core'
// import store from '@/store'
import { Notify } from 'vant'
import _ from 'lodash'
import config from '@/config.js'
import { watch } from 'vue'
export default {
  data(){
    return {
      configUrl: config
    }
  },
  setup(){
    const active = ref(0)
    const status = ref(-1)
    const router = useRouter()
    const onClickLeft = () => {
        router.back()
    }
    const handle = () =>{
        router.push('/addUpdateAccount')
    }
    const activityList = ref([])
    const accout = ref(null)
    const getAmountFun = () => {
      if(localStorage.getItem('storeId')){
        getAmount(localStorage.getItem('storeId')).then(res => {
          if (res.success) {
            accout.value = res.data
          }
        })
      }
    }
    watch(() => active.value, (newV) => {
      switch(newV){
        case 0:
          status.value = -1
          break
        case 1:
          status.value = 0
          break
        case 2:
          status.value = 1
          break
        case 3:
          status.value = 2
          break
      }
      getActivityList()
    })
    const getActivityList = () => {
      const params = {
        pageSize: 1000,
        curPage:1,
        storeId: localStorage.getItem('storeId'),
        status: status.value
      }
        activityQueryPage(params).then(res => {
          if (res.success) {
            activityList.value = res.data.records
          }
        })
    }
    const getStatus = (status) => {
      switch(status) {
        case 0:
          return '未发车'
        case 1:
          return '进行中'
        case 2:
          return '已结束'
      }
    }
    const begin = _.throttle((id) => {
      //
      activityBegin({id}).then(res => {
          console.log(res)
        if (res.success) {
          console.log(res)
          Notify({
            type: 'success',
            message: '发车成功'
          })
          getActivityList()
        }
      })
    }, 1500)
    // 结束弹窗
    const closeId = ref(null)
    const closeDia = ref(false)
    const closeFn = (id) => {
      closeId.value = id
      closeDia.value = true
    }
    const end = _.throttle(() => {
      activityEnd({id: closeId.value}).then(res => {
        if (res.success) {
          Notify({
            type: 'success',
            message: '已结束'
          })
          closeDia.value = false
          getActivityList()
        }
      })
    }, 1500)
    const activeId = ref(0)
    const closeCancel = ref(false)
    const cancelDia = (id) => {
      activeId.value = id
      closeCancel.value = true
    }
    const cancelActiveFn = () => {
      cancelActiveApi(activeId.value).then((res) => {
        if (res.success) {
          Notify({
            type: 'success',
            message: '已取消！'
          })
          closeCancel.value = false
          getActivityList()
        }
      })
    }

    const toDetail = (item) => {
      router.push(`/activeDetail?id=${item.activeId}&status=${item.status}`)
    }
    onMounted(() => {
      getActivityList()
      getAmountFun()
    })
    return {
      // data
      closeId,
      closeDia,
      status,
      active,
      accout,
      getAmountFun,
      onClickLeft,
      handle,
      activityList,
      getStatus,
      begin,
      end,
      closeCancel,
      // fn
      cancelDia,
      toDetail,
      closeFn,
      cancelActiveFn
    }
  }
}
</script>
