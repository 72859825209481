<template>
  <div>
    <van-nav-bar
      title="选角发车"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="store-page">
      <header>
          <div v-if="dramaData" class="header-scan">
            <img :src="`${config.imgRoot}${dramaData.dramaImgUrl}`" alt="">
            <div class="scan-right">
              <div class="scan-title">{{ dramaData.dramaName }}</div>
              <div class="scan-con">{{ dramaData.dramaRoleList.length }}人·{{ dramaData.dramaTypeName }}</div>
            </div>
          </div>
          <div class="QR-code" v-if="qrCodeUrl">
              <img :src="qrCodeUrl" alt="">
              <div class="QR-title" @click="generateQrcodeByParamFunc">点击二维码刷新</div>
          </div>
          <div class="footer-scan">
            向玩家展示二维码进行扫码选角
          </div>
      </header>
      <div v-if="(config.mode === 'dev' || config.mode === 'test') && startCardDisabled" class="one_click_departure">
        <van-button type="primary" color="#20B2E0" @click="addInfo" block>一键发车</van-button>
      </div>
      <main v-if="activeData">
        <div class="main-header">已加入人数 <span>{{activeData.joinNum > activeData.roleNum ? activeData.roleNum : activeData.joinNum}}/{{activeData.roleNum}}</span></div>
        <div class="main-con" v-if="activeData.roleUserInfoVoList.length">
          <template v-for="(item, index) in activeData.roleUserInfoVoList" :key="item.activeId">
            <div class="main-item-box" v-if="index <= activeData.roleNum">
              <img :src="item.headImg" alt="">
              <div class="nick">{{item.nickName}}</div>
              <div class="role" v-if="item.roleName">{{item.roleName}}</div>
              <div class="noSel" v-else>未选择</div>
            </div>
          </template>

          <div class="main-item-box"  v-for="item in (activeData.roleNum - (activeData.roleUserInfoVoList.length > activeData.roleNum ? activeData.roleNum : activeData.roleUserInfoVoList.length))" :key="item">
            <div class="empty"></div>
            <div class="noIn">待加入</div>
          </div>
        </div>
      </main>
      <!-- v-if="activeData" -->
      <footer v-if="activeData">
        <van-button type="primary" @click="closeDia = true"  color="#D3D3D3" style="width: 120px; margin-right: 10px; color: rgba(0,0,0,0.5);" >取消</van-button>
        <van-button type="primary" :disabled="(startDisabled || status) || accout.cooperativeState!==0" color="#20B2E0" @click="showBottom = true" block>发车</van-button>
      </footer>
      <van-popup
        v-model:show="showBottom"
        round
        position="bottom"
        :style="{ height: '65%' }">
        <div class="popup-wrap">
          <div class="popup-header">
            <img :src="`${config.imgRoot}${dramaData.dramaImgUrl}`" alt="">
            <div class="popup-header-right">
              <div style="flex: 1;">
                <div class="drama-title">{{dramaData.dramaName}}</div>
                <div class="role-title">当前剧本</div>
              </div>
              <div class="drama-price">￥{{ dramaPriceData.dramaPrice *  (100 - divideInto) / 100}} / {{ dramaData.dramaRoleList.length}}人</div>
            </div>
          </div>
          <div class="popup-main">
            <div class="popup-main-header">
              <div class="popup-main-header-tip">本次发车需扣除</div>
              <div class="popup-main-header-price">￥{{dramaData.dramaRoleList.length * dramaPriceData.dramaPrice * (100 - divideInto) / 100}}</div>
            </div>
            <div class="popup-main-con">
              <img src="./img/money.png" alt="">
              <div class="popup-main-con-right">
                <div class="popup-main-con-tip">账户余额</div>
                <div class="popup-main-con-price">￥ {{accout.amount}}</div>
              </div>
            </div>
            <div class="popup-main-footer">
                <div v-if="accout.amount >= dramaData.dramaRoleList.length * dramaPriceData.dramaPrice* (100 - divideInto) / 100" class="popup-main-footer-tip">发车费用会在发车一段时间内扣除</div>
                <div v-else class="popup-main-footer-tip">余额不足，请前往充值</div>
                <van-button v-if="accout.amount >= dramaData.dramaRoleList.length * dramaPriceData.dramaPrice * (100 - divideInto) / 100"  type="primary" color="#20B2E0" @click="beginActiveFn" :loading="beginLoading" block>发车</van-button>
                <van-button  v-else type="primary" color="#20B2E0" @click="recharge" block>充值</van-button>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <van-dialog v-model:show="closeDia" :show-cancel-button="false">
        <template #title>
        </template>
        <div class="unbinding-con">
          <div class="unbinding-tip">是否取消发车！</div>
        </div>
        <template #footer>
          <div class="unbinding-footer">
            <van-button  class="cancel" @click="closeDia = false" color="#EBEBEB ">取消</van-button>
            <van-button class="submit" :loading="cancelLoading" @click="cancelActiveFn" color="#20B2E0" >确认</van-button>
          </div>
        </template>
      </van-dialog>
      <div class="Recharge">
        <Recharge :show="showRecharge" @cancel="cancel" @getAmountFun="getAmountFun"></Recharge>
      </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onBeforeUnmount  } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {  getAmount, generateQrcodeByParam, getDramaActiveInfoByStoreId, getDramaDetail, cancelActiveApi, activityBegin, getDramaPrice, addPlayerInfo, getInfoByStoreDrama } from '@request/api'
import { Notify } from 'vant'
// import QrcodeVue from 'qrcode.vue'
// import store from '@/store'
// import { getConfigUrl } from '@/utils/configUrl.js'
import config  from '@/config'
import Recharge from '@/components/Recharge.vue'
export default {
  //导入组件
  components: {
    Recharge
  },
  setup(){
    const onClickLeft = () => history.back()
    const columns = [{name:'护照',value:'0'},{name:'身份证',value:'1'},{name:'港澳通行证',value:'2'}];
    const customFieldName = {
      text:'name'
    };
    const qrCodeUrl = ref(null)
    const route = useRoute()
    const userType = ref(null)
    const limitNum = ref(null) // 限制人数
    // 玩家物料二维码
    // qrCodeUrl.value = getConfigUrl('user')+`?storeId=${localStorage.getItem('storeId')}`
    const router = useRouter()
    const storeName = ref(route.query.storeName)
    const venueName = ref(route.query.venueName)
    const girdList = ref([])
    const showPicker = ref(false);
    const showBottom = ref(false)
    const showRecharge = ref(false)
    const cancel = () => {
      showRecharge.value = false
    }
    const data =  reactive({
      isInput: false
    })
    const formData = reactive({
      name: undefined,
      identityCard: undefined,
      mobile: undefined,
      cardType: '1'
      // comment: undefined
    })
    const divideInto = ref(0)

    const navTo = (item) => {
      let url = ''
      switch(item.menuName) {
        case '账户管理':
          url = `/open`
          break
        case '角色/眼镜绑定':
          url = `/glasses?storeId=${localStorage.getItem('storeId')}`
          break
        case '剧本杀发车管理':
          url = `/activityManagement`
          break
        case '开本数据统计':
          url = `/statistics`
          break
      }
      router.push(url)
    }
    const dramaData = ref(null)
    const getDramaDetailFn = async () => {
      const res = await getDramaDetail(route.query.dramaId)
      if (res.success) {
        dramaData.value = res.data
        limitNum.value = res.data.limitNum
      }
    }
    getDramaDetailFn()

    const startDisabled = ref(true)
    const startCardDisabled = ref(true)
    const getDisabled = (activeData) => {
      // if (activeData.joinNum == dramaData.value.dramaRoleNum) {
      //   startDisabled.value = !activeData.roleUserInfoVoList.every(item => item.roleName)
      // }
      if (activeData.joinNum >= limitNum.value || activeData.joinNum <= dramaData.value.dramaRoleNum ) {
        startDisabled.value = !activeData?.roleUserInfoVoList.every(item => item.roleName)
      }
    }
    const getStartDisabled = (activeData) => {
      if (activeData&&activeData?.joinNum < dramaData.value.dramaRoleNum) {
        startCardDisabled.value = true
      } else {
        startCardDisabled.value = false
      }
    }
    const activeData = ref(null) // 活动数据
    const status = ref(false)
    // 获取活动详情
    const getDramaActiveInfo = async () => {
      const res = await getDramaActiveInfoByStoreId(route.query.dramaId, localStorage.getItem('storeId'))
      if (res.data) {
        activeData.value = res.data
        status.value = res.data.status
        getDisabled(activeData.value)
        getStartDisabled(activeData.value)
      }
    }
    getDramaActiveInfo()
    let timer = setInterval(()=> {
      getDramaActiveInfo()
    }, 5000)
    onBeforeUnmount(() => {
      clearInterval(timer)
    })
    // 获取门店二维码
    const generateQrcodeByParamFunc = () => {
      if(localStorage.getItem('storeId')){
        generateQrcodeByParam({ dramaId: route.query.dramaId, storeId: localStorage.getItem('storeId') }).then((res) => {
          if (res.success) {
            // 玩家物料二维码
            qrCodeUrl.value = res.data
          }
          console.log(res)
        })
      }

    }
    generateQrcodeByParamFunc()
    // 取消发车
    const closeDia = ref(false)
    const cancelLoading = ref(false)
    const beginLoading = ref(false)
    const cancelActiveFn = async() => {
      cancelLoading.value = true
      const res = await cancelActiveApi(activeData.value.activeId)
      closeDia.value = false
      cancelLoading.value = false
      if (res.success) {
        Notify({
          type: 'success',
          message: '已取消！'
        })
        router.go(-1)
      }
    }
    const beginActiveFn = async () => {
      beginLoading.value = true
      const res = await activityBegin({id: activeData.value.activeId})
        beginLoading.value = false
        showBottom.value = false
        if (res.success) {
          router.push(`/success?activeId=${activeData.value.activeId}`)
        }
    }
    const accout = ref(null)
    const getAmountFun = () => {
      if(localStorage.getItem('storeId')){
        getAmount(localStorage.getItem('storeId')).then(res => {
          if (res.success) {
            accout.value = res.data
          }
        })
      }
    }
    getAmountFun()
    const dramaPriceData = ref(null)
    const getDramaPriceFn = async () => {
     if(localStorage.getItem('storeId')){
       const res = await getDramaPrice(route.query.dramaId,localStorage.getItem('storeId'))
       if (res.success) {
         dramaPriceData.value = res.data
       }
     }
    }
    getDramaPriceFn()
    const recharge = () => {
      showRecharge.value = true
    }
    const addInfo = async () => {
      const param = {
        dramaId: route.query.dramaId,
        storeId: localStorage.getItem('storeId')
      }
      const res = await addPlayerInfo(param)
      if (res.code === 200) {
        getDramaActiveInfo()
      }
    }
    // 获取商户端剧本分成
    const getDivideInto = async () =>{
      const param = {
        storeId: localStorage.getItem('storeId'),
        dramaId: route.query.dramaId
      }
      const res = await getInfoByStoreDrama(param)
      if (res && res.code === 200) {
        divideInto.value = res.data.storeDramaDivideInto
      }
    }
    getDivideInto()
    return {
      // data
      showRecharge,
      dramaPriceData,
      cancelLoading,
      beginLoading,
      dramaData,
      showBottom,
      config,
      activeData,
      divideInto,
      accout, // 账户
      girdList,
      closeDia,
      startDisabled,
      startCardDisabled,
      status,
      navTo,
      ...toRefs(data),
      ...toRefs(formData),
      columns,
      customFieldName,
      showPicker,
      storeName,
      venueName,
      qrCodeUrl,
      userType,
      // fn
      cancel,
      recharge,
      cancelActiveFn,
      beginActiveFn,
      onClickLeft,
      generateQrcodeByParamFunc,
      getStartDisabled,
      getDisabled,
      addInfo,
      getDivideInto
    }
  }
}
</script>
<style lang="less" scoped>
.Recharge{
  /deep/ .van-dialog__header {
    padding: 0;
  }
}
.store-page {
  padding: 16px;
  position: relative;
  box-sizing: border-box;
  overflow: auto;
  background: rgba(237,237,237,0.9);
  /deep/ .van-nav-bar {
    background: none;
  }
  /deep/ .van-hairline--bottom:after {
    display: none;
  }
  .store-bg {
    position: absolute;
    left: 44px;
    top: 20px;
  }
  .back-title {
    margin-left: 4px;
    color: rgb(217, 217, 217);
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: undefined;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .one_click_departure {
    margin-top: 20px;
    /deep/ .van-button {
      border-radius: 8px;
    }
  }
}
main {
  .main-header {
    padding: 15px 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    line-height: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    span {
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #20B2E0;
      line-height: 29px;
    }
  }
  .main-con {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    .main-item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;
      width: 30%;
      background: #FFFFFF;
      box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      margin-left: 10px;
      margin-bottom: 10px;
      height: 100px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }
      .empty {
        width: 60px;
        height: 60px;
        background: #DADADA;
        border-radius: 100%;
      }
      .nick {
        margin-top: 6px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 18px;
      }
      .role {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #20B2E0;
        line-height: 16px;
      }
      .noIn {
        margin-top: 6px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.4);
        line-height: 18px;
      }
      .noSel {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.4);
        line-height: 16px;
      }
    }
  }
}
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
header {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
  border: 2px solid #20B2E0;
  height: 400px;
  .header-scan {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D2D2D2;
    img {
      margin-right: 16px;
      width: 51px;
      height: 72px;
      border-radius: 4px;
    }
    .scan-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      line-height: 25px;
    }
    .scan-con {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 18px;
    }
  }
}
.header-title, .item-title {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}
.QR-code {
    flex: 1;
    margin-top: 5px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    .QR-title {
      padding: 0 8px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.3);
      line-height: 20px;
      text-align: center;
      background: #F2F2F2;
      border-radius: 13px;
    }
    img{
        width: 180px;
        height: 180px;
    }
}
.footer-scan {
  height: 58px;
  line-height: 58px;
  border-top: 1px solid #eee;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
}
footer {
  padding: 10px 0;
  display: flex;
  /deep/ .van-button {
    border-radius: 8px;
  }
}
.popup-wrap {
  padding: 0 16px;
  .popup-header {
    display: flex;
    padding: 16px 0;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    img {
      margin-right: 16px;
      width: 51px;
      height: 72px;
      border-radius: 4px;
    }
    .popup-header-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .drama-title {
        margin-bottom: 2px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 25px;
      }
      .role-title {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.5);
        line-height: 18px;
      }
      .drama-price {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 25px;
      }
    }
  }
  .popup-main {
    .popup-main-header {
      text-align: center;
      padding: 20px 0;
      .popup-main-header-tip {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.5);
          line-height: 20px;
        }
        .popup-main-header-price{
          font-size: 48px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #000000;
          line-height: 56px;
        }
      }
  }
  .popup-main-con {
    margin-bottom: 20px;
    padding: 16px;
    display: flex;
    align-items: center;
    background: #EEEEEE;
    border-radius: 8px;
    img {
      margin-right: 10px;
      width: 19px;
      height: 18px;
    }
    .popup-main-con-right {
      flex: 1;
      display: flex;
      .popup-main-con-tip {
        flex: 1;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
      }
      .popup-main-con-price {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
      }
    }
  }
  .popup-main-footer {
    padding-bottom: 20px;
    text-align: center;
    .popup-main-footer-tip {
      margin-bottom: 8px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 17px;
    }
    /deep/ .van-button {
      border-radius: 8px;
    }
  }
}
.unbinding-con {
  padding: 32px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .unbinding-tip {
    margin-bottom: 2px;
    height: 29px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 29px;
  }
}
.unbinding-footer {
  padding: 0px 16px 24px;
  display: flex;
  justify-content: space-between;
  /deep/ .van-button {
    width: 136px;
    height: 44px;
    background: #EBEBEB;
    border-radius: 8px;
  }
  .cancel {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
  }
}
</style>
