const SETOPENID = 'setOpenId'
const SETGLASSES = 'setGlasses'
const SETSTOREID = 'setStoreId'
const app = {
    namespaced: true,
    state: {
        openId: null,
        glasses: null,
        storeId: null
    },

    mutations: {
        [SETOPENID]: (state, openId) => {
            state.openId = openId
        },
        [SETGLASSES]: (state, glasses) => {
            state.glasses = glasses
        },
        [SETSTOREID]: (state, storeId) => {
            state.storeId = storeId
        },
    },

    actions: {
    }
}

export default app
