<template>
  <div>
    <van-nav-bar
      title="选择剧本"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="store-page">
      <van-tabs v-model:active="active" title-inactive-color="#666" :border="false" type="card" color="#20B2E0">
        <van-tab title="剧本列表">
          <div class="list-wrap"  v-if="dramaList">
<!--            <div class="item-wrap" :class="{ select: selectId === item.dramaId, disabled: item.status != 0 || item.dnum != item.rnum }" v-for="item in dramaList" :key="item.id"-->
<!--            @click="itemClick(item)">-->
              <div class="item-wrap" :class="{ select: selectId === item.dramaId, disabled: item.status != 0 }" v-for="item in dramaList" :key="item.id"
                   @click="itemClick(item)">
<!--              <div class="item-box" :class="{ select: selectId === item.dramaId, disabled: item.status != 0 || item.dnum != item.rnum }">-->
                <div class="item-box" :class="{ select: selectId === item.dramaId, disabled: item.status != 0}">
                <img :src="`${config.imgRoot}${item.dramaImgUrl}`" alt="">
                <div class="con-wrap">
                  <div class="con-left">
                    <div class="con-title">{{item.dramaName}}</div>
                    <div class="con-mes">{{ item.dnum }}人·{{ item.name }}</div>
<!--                    <div class="con-tip" v-if="item.dnum != item.rnum">设备未绑定角色</div>-->
                  </div>
<!--                  <div class="con-right" v-if="item.status === 0 && item.dnum == item.rnum">可发车</div>-->
                  <div class="con-right" v-if="item.status === 0">可发车</div>
                  <div style="color: rgba(0,0,0,0.25);" class="con-right" v-if="item.status === 1">发车中</div>
                </div>
              </div>
            </div>
          </div>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <van-tab title="最近开本">
          <div class="list-wrap" v-if="latelyList">
            <div class="item-wrap" v-for="item in latelyList" :key="item.id" @click="goActivity(item.activityId)">
              <div class="item-box">
                <img :src="`${config.imgRoot}${item.dramaImgUrl}`" alt="">
                <div class="con-wrap">
                  <div class="con-left">
                    <div class="con-title">{{item.dramaName}}</div>
                    <div class="con-mes">{{ item.dramaRoleNum }}人·{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <van-empty v-else description="暂无数据" />
        </van-tab>
        <div class="footer">
          <van-button type="primary" :disabled="!selectId" @click="goDetail" color="#20B2E0" block>确定</van-button>
        </div>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref  } from 'vue'
import {  useRoute, useRouter } from 'vue-router'
import { queryStoreDramaPage, getByDateUsefulDetail } from '@request/api'
// import QrcodeVue from 'qrcode.vue'
// import store from '@/store'
import config  from '@/config'
export default {
  //导入组件
  components: {
    // QrcodeVue
  },
  setup(){
    const onClickLeft = () => history.back()
    const columns = [{name:'护照',value:'0'},{name:'身份证',value:'1'},{name:'港澳通行证',value:'2'}];
    const customFieldName = {
      text:'name'
    };
    const route = useRoute()
    const userType = ref(null)
    // 玩家物料二维码
    const router = useRouter()
    const storeName = ref(route.query.storeName)
    const venueName = ref(route.query.venueName)
    const girdList = ref([])
    const showPicker = ref(false);
    const data =  reactive({
      isInput: false
    })
    const selectId = ref('')
    const formData = reactive({
      name: undefined,
      identityCard: undefined,
      mobile: undefined,
      cardType: '1'
      // comment: undefined
    })
    const navTo = (item) => {
      console.log(item)
    }
    const latelyList = ref(null)
    const dramaList = ref(null)
    const queryStoreDramaPageFn = async () => {
          if(localStorage.getItem('storeId')){
            const params = {
              storeId: localStorage.getItem('storeId')
            }
            const res = await queryStoreDramaPage(params)
            if (res.success) {
              dramaList.value = res.data.records
            }
          }
    }
    queryStoreDramaPageFn()
    const getByDateUsefulDetailFn = async () => {
          if(localStorage.getItem('storeId')){
            const res = await getByDateUsefulDetail(localStorage.getItem('storeId'))
            if (res.success) {
              latelyList.value = res.data.records
            }
          }
    }
    getByDateUsefulDetailFn()
    const itemClick = (e) => {
      selectId.value = null
      // if (e.status !== 0 || e.dnum != e.rnum) return
      if (e.status !== 0) return
      selectId.value = e.dramaId
    }
    const goActivity = (activeId) => {
      router.push(`/activeDetail?id=${activeId}`)
    }

    const goDetail = () => {
      if (selectId.value) {
        router.push(`/angleSelect?dramaId=${selectId.value}`)
      }
    }
    return {
      // data
      selectId,
      dramaList,
      latelyList,
      girdList,
      navTo,
      ...toRefs(data),
      ...toRefs(formData),
      columns,
      customFieldName,
      showPicker,
      storeName,
      venueName,
      userType,
      // fn
      itemClick,
      goActivity,
      config,
      goDetail,
      onClickLeft
    }
  }
}
</script>
<style lang="less" scoped>
.store-page {
  padding-top: 15px;
  position: relative;
  background: #EEEEEE;
  height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /deep/.van-tabs__nav--card {
    margin: 0 90px;
  }
  /deep/ .van-tab, /deep/.van-tabs__nav--card {
    border: none;
    background: transparent;
  }
  /deep/ .van-tab, /deep/ .van-tab--card {
    border-radius: 13px;
    background: transparent;
    border: none;
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 12px;
    /deep/ .van-button {
      border-radius: 8px;
    }
  }
}
.list-wrap {
  padding: 16px 12px;
    .select {
      border-color: #20B2E0!important;
    }
    .disabled {
      background: #E0E0E0 !important;
    }
  .item-wrap {
    margin-bottom: 8px;
    padding: 4px;
    border-radius: 12px;
    background: #fff;
    border: 2px solid transparent;
    .select {
      background: #BEE1EC!important;
    }
    .disabled {
      background: #E0E0E0 !important;
    }
    .item-box {
      padding: 12px 20px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      img {
        margin-right: 20px;
        width: 68px;
        height: 96px;
        display: block;
        border-radius: 4px;
      }
    }
  }
}
.con-wrap {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .con-left {
    .con-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      line-height: 25px;
    }
    .con-mes {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 18px;
    }
    .con-tip {
      margin-top: 8px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #E02020;
      line-height: 20px;
    }
  }
  .con-right {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6DD400;
  }
}
header {
  margin-top: 240px;
  margin-bottom: 30px;
  padding: 0 20px;
  .header-title {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    img {
      margin-left: 5px;
      width: 17px;
      height: 15px;
    }
  }
  .store-title {
    font-size: 27px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 38px;
  }
}
main {
  padding: 0 16px;
  .nav-item {
    margin-bottom: 16px;
    padding: 0 12px;
    background: #FFFFFF;
    border-radius: 8px;
    .nav-header {
      display: flex;
      align-items: center;
      padding: 10px 0;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.7);
      img {
        margin-right: 6px;
        width: 4px;
        height: 20px;
      }
    }
    .nav-wrap {
      margin-top: 5px;
      padding-bottom: 20px;
      display: flex;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.7);
      .nav-box {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-bottom: 7px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.title-box {
  .title {
    color: rgb(255, 255, 255);
    font-family: PingFang SC;
    font-size: 32px;
    font-weight: undefined;
    line-height: 47px;
    letter-spacing: 0px;
    text-align: left;
  }
  .tip {
    color: rgba(255, 255, 255, 0.4);
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: undefined;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
.header-title, .item-title {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}
.QR-code {
    margin-top: 5px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    .QR-title {
      color: rgba(0, 0, 0, 0.25);
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: undefined;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
    }
    img{
        width: 210px;
        height: 210px;

    }
}
.nav {
  margin-top: 20px;
  padding: 8px;
  border-radius: 16px;
  background: #fff;
}
.left-warp {
  display: flex;
  align-items: center;
}
</style>
