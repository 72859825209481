// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/home/index.vue'
import storeList from '../views/home/pageHome.vue'
import dramaList from '../views/drama/index.vue'
import Store from '../views/store/store.vue'
import accountManagement from '../views/accountManagement/accountManagement.vue'
import addUpdateAccount from '../views/accountManagement/addUpdateAccount.vue'
import Open from '../views/accountManagement/open.vue'
import Withdrawal from '../views/accountManagement/withdrawal.vue'
import capitalFlow from '../views/accountManagement/capitalFlow.vue'
import Glasses from '../views/roleGlasses/glasses.vue'
import addGlasses from '../views/roleGlasses/addGlasses.vue'
import activityManagement from '../views/activityManagement/activity.vue'
import activeDetail from '../views/activityManagement/detail.vue'
import statistics from '../views/statistics/index.vue'
import binding from '../views/roleGlasses/binding.vue'
import angleSelect from '../views/angleSelect/index.vue'
import success from '../views/success/index.vue'

// createRouter 创建路由实例
const router = createRouter({
  base: "/mis-automatic-app",
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      component: Home
    },{
      path: '/store',
      component: Store
    },{
      path: '/storeList',
      component: storeList
    },{
      path: '/dramaList',
      component: dramaList
    },
    {
      path: '/accountManagement',
      component: accountManagement
    },
    {
      path: '/addUpdateAccount',
      component: addUpdateAccount
    },
    {
      path: '/open',
      component: Open
    },
    {
      path: '/withdrawal',
      component: Withdrawal
    },
    {
      path: '/capitalFlow',
      component: capitalFlow
    },
    {
      path: '/glasses',
      component: Glasses
    },
    {
      path: '/addGlasses',
      component: addGlasses
    },
    {
      path: '/activityManagement',
      component: activityManagement
    },
    {
      path: '/activeDetail',
      component: activeDetail
    },
    {
      path: '/statistics',
      component: statistics
    },
    {
      path: '/binding',
      component: binding
    },
    {
      path: '/angleSelect',
      component: angleSelect
    },
    {
      path: '/success',
      component: success
    }
  ]
})
 
// 抛出路由实例, 在 main.js 中引用
export default router
