<style lang="less" scoped>
    .title {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #666;
    }
    main {
        position: absolute;
        top: 50%;
        width: 100%;
        font-size: 16px;
        color: #666;
        vertical-align: middle;
        text-align: center;
    }
</style>
<template>
    <div>
    <van-nav-bar
        @click-left="onClickLeft"
    >
        <template #title>
        <div class="title">提现</div>
        </template>
        <template #left>
        <van-icon name="arrow-left" color="#323233" size="24" />
        </template>
    </van-nav-bar>
    <main>
        功能开发中，敬请期待
    </main>
    </div>
</template> 
<script>
import { useRouter } from 'vue-router'
export default {
    setup(){
    const router = useRouter()
    const onClickLeft = () => {
        router.back()
    }
    return {
        onClickLeft
    }
    }
}
</script>