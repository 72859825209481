<style lang="less" scoped>
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
main {
  padding: 0 15px;
}
.item-wrap {
  padding: 5px 15px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  div {
    line-height: 25px;
    font-size: 14px;
    color: #666;
  }
}
.total {
  padding: 15px 0;
  font-size: 14px;
  color: #333;
}
</style>
<template>
  <div>
    <van-nav-bar
      @click-left="onClickLeft"
    >
      <template #title>
        <div class="title">开本数据统计</div>
      </template>
      <template #left>
        <van-icon name="arrow-left" color="#323233" size="20" />
      </template>
    </van-nav-bar>
    <div>
        <van-field
            v-model="dateRangeTs"
            label="开本日期"
            placeholder="请选择开本日期"
            readonly
            @focus="selectDatePicker"
        ></van-field>
    </div>
    <main v-if="flowList&&flowList.length>0">
      <div class="total">有效开本数：{{flowList.length}}</div>
      <div class="item-wrap" v-for="(item, index) in flowList" :key="index">
          <div >剧本：{{item.dramaName}}</div>
          <div>发车时间： {{item.beginTime}}</div>
      </div>
    </main>
    <van-empty v-else description="暂无数据" />
    <van-action-sheet v-model:show="isShowDatePicker">
      <van-datetime-picker
          v-if="!isShowEndDatePicker"
          type="date"
          v-model="beginTs"
          confirm-button-text="确定"
          cancel-button-text="重置"
          title="开始时间"
          @confirm="isShowEndDatePicker = true"
          @cancel="beginCancel"
          @close="isShowDatePicker = false"
      />
      <van-datetime-picker
          v-if="isShowEndDatePicker"
          type="date"
          v-model="endTs"
          confirm-button-text="查询"
          cancel-button-text="重置"
          title="结束时间"
          :min-date="beginTs || new Date()"
          @confirm="closeTimePicker"
          @cancel="endCancel"
          @close="closeTimePicker"
      />
    </van-action-sheet>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { getByDateUsefulDetailApi } from '@request/api'
// import store from '@/store'
export default {
  setup(){
    const checked = ref([]);
    const checkboxGroup = ref(null);
    const flowList = ref(null)
    const checkAll = () => {
      checkboxGroup.value.toggleAll(true);
    }
    const toggleAll = () => {
      checkboxGroup.value.toggleAll();
    }

    const router = useRouter()
    const onClickLeft = () => {
        router.back()
    }
    const submit = () => {
    }

    // 日期格式化
    const dateFormat = (value, format) => {
        if (!value) return
        if (!(value instanceof Date)) {
            value = new Date(value.replace(/-/g, '/')) // 解决日期转换在ios系统的手机上显示NAN的问题
        }
        const o = {
            'M+': value.getMonth() + 1, // month
            'd+': value.getDate(), // day
            'h+': value.getHours(), // hour
            'm+': value.getMinutes(), // minute
            's+': value.getSeconds(), // second
            'q+': Math.floor((value.getMonth() + 3) / 3), // quarter
            S: value.getMilliseconds() // millisecond
        }

        if (!format || format === '') {
            format = 'yyyy-MM-dd hh:mm:ss'
        }

        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (value.getFullYear() + '').substr(4 - RegExp.$1.length))
        }

        for (const k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
                )
            }
        }
        return format
    }
    const beginTs = ref(new Date(2022, 8, 1))
    const endTs = ref(new Date())
    const dateRangeTs = ref('')
    const isShowDatePicker = ref(false) // 是否展示时期选择
    const isShowEndDatePicker = ref(false) // 是否展示时期选择
    // 弹出选择日期控件
    const selectDatePicker = () => {
        isShowDatePicker.value = true
    }

    const closeTimePicker = () => {
        isShowDatePicker.value = false
        isShowEndDatePicker.value = false
        getByDateUsefulDetailFnc()
    }
    const getByDateUsefulDetailFnc = () => {
      getByDateUsefulDetailApi(dateFormat(beginTs.value, 'yyyy-MM-dd') || '', dateFormat(endTs.value, 'yyyy-MM-dd') || '', localStorage.getItem('storeId')).then(res => {
        if (res.success) {
          flowList.value = res.data
        }
      })
    }
    const beginCancel = () => {
      beginTs.value = ''
      endTs.value = ''
      isShowDatePicker.value = false
    }
    const endCancel = () => {
      beginTs.value = ''
      endTs.value = ''
      isShowDatePicker.value = false
    }
    // 监听起始创建时间
    watch(
        () => [beginTs.value],
        () => {
            dateRangeTs.value = `${dateFormat(beginTs.value, 'yyyy-MM-dd') || ''} ~ ${
                dateFormat(endTs.value, 'yyyy-MM-dd') || ''
            }`
            if (!beginTs.value && !endTs.value) {
              dateRangeTs.value = ''
            }
        }
    )
    // 监听结束创建时间
    watch(
        () => [endTs.value],
        () => {
            dateRangeTs.value = `${dateFormat(beginTs.value, 'yyyy-MM-dd') || ''} ~ ${
                dateFormat(endTs.value, 'yyyy-MM-dd') || ''
            }`
            if (!beginTs.value && !endTs.value) {
              dateRangeTs.value = ''
            }
        }
    )
    onMounted(() => {
      getByDateUsefulDetailFnc()
    })

    return {
      flowList,
      onClickLeft,
      submit,
      checked,
      checkAll,
      toggleAll,
      checkboxGroup,
      // data
      beginTs,
      endTs,
      isShowDatePicker,
      isShowEndDatePicker,
      dateRangeTs,
      // func
      selectDatePicker,
      closeTimePicker,
      beginCancel,
      endCancel
    }
  }
}
</script>
