<template>
  <div class="store-page">
    <header>
      <div class="header-title">我的门店 <img @click="switchStore" src="./img/switch.png" alt=""></div>
      <div class="store-title">{{fullName}}</div>
    </header>
    <main>
      <div class="nav-item">
        <div class="nav-header"><img src="./img/bar.png" alt="">开本发车</div>
        <div class="nav-wrap">
          <div class="nav-box"  @click="navTo({ menuName: '选角发车' })">
            <img src="./img/angle.png" alt=""> <span>选角发车</span>
          </div>
          <template v-for="(item, index) in girdList" :key="index" >
            <div v-if="item.menuName != '我的账户'" @click="navTo(item)"   class="nav-box">
              <img :src="item.icon" alt="">
              <span>{{item.menuName}}</span>
            </div>
          </template>
        </div>
      </div>

      <div class="nav-item">
        <div class="nav-header"><img src="./img/bar.png"  alt="">账户管理</div>
        <div class="nav-wrap">
          <template v-for="(item, index) in girdList" :key="index" >
            <div v-if="item.menuName == '我的账户'" @click="navTo(item)"   class="nav-box">
              <img :src="item.icon" alt="">
              <span>{{item.menuName}}</span>
            </div>
          </template>
          <div class="nav-box" @click="navTo({ menuName: '子账号' })">
            <img src="./img/user.png" alt=""> <span>子账号</span>
          </div>
        </div>
      </div>
        <!-- <van-cell v-if="userType == 1" url="#/accountManagement" is-link>
          <div class="left-warp">
            <img src="./img/user.png" alt=""> <span>子账号管理</span>
          </div>
        </van-cell>
        <van-cell v-for="(item, index) in girdList"  @click="navTo(item)" :key="index" is-link>
          <template #title>
            <div class="left-warp">
              <img :src="item.icon" alt=""> <span>{{item.menuName}}</span>
            </div>
          </template>
        </van-cell> -->
    </main>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted  } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { queryByLogin } from '@request/api'
import { Notify } from 'vant'
import { bindStore, queryPageStore, bindRoleStore ,getOpenIdApi } from '@request/api'
import config from '@/config'
// import QrcodeVue from 'qrcode.vue'
import store from '@/store'
// import { getConfigUrl } from '@/utils/configUrl.js'
export default {
  //导入组件
  components: {
    // QrcodeVue
  },
  setup(){
    // 情况缓存
    if (localStorage.getItem('openId') === 'undefined') {
      localStorage.clear()
    }
    const queryPageStoreFun = () => {
      queryPageStore({})
      .then(
        res => {
        if (res.success) {
          localStorage.setItem('storeId', res.data.records[0].storeId)
          getAuth()
        }
      }).catch(() => {
      })
    }

    // 门店绑定管理员
    const bindStoreFun = async (data) => {
      const res = await bindStore(data)
      if (res.success) {
        Notify({
          type: 'success',
          message: '绑定成功！'
        })
        window.history.replaceState(null, "", window.location.origin)
        window.history.go(0)
      }
      getAuth()
    }
    // 店员绑定
    const bindRoleStoreFun = async (data) => {
      const res = await bindRoleStore(data)
      if (res.success) {
        Notify({
          type: 'success',
          message: '绑定成功！'
        })
        window.history.replaceState(null, "", window.location.origin)
        window.history.go(0)
      }
      getAuth()
    }
    // 解析地址
    const getCode = () => {
        var url = location.search; //获取url中"?"符后的字串
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        return theRequest;
    }
    // 获取openId
    const getOpenId = (code) => {
      getOpenIdApi({ code }).then(res => {
        if (res.data && res.code === 200) {
          store.commit('app/setOpenId', res.data.openid)
          localStorage.setItem('openId', res.data.openid)
          localStorage.setItem('headImg', res.data.headimgurl)
          localStorage.setItem('nickName', res.data.nickname)
          const storeId = getCode().storeId
          const systemType = getCode().systemType
          const msgId = getCode().msgId
          const dramaId = getCode().dramaId
          if (systemType === 'user') {
            window.location.href = `${config.userUrl}?storeId=${storeId}&openId=${res.data.openid}&msgId=${msgId}&dramaId=${dramaId}`
          }
          const params = {}
          if (storeId) {
              let data = getCode()
              console.log(data.storeId)
              params.storeId = data.storeId
              localStorage.setItem('storeId', data.storeId)
              if (data.userRoleId) {
                params.userRoleId = data.userRoleId
              }
              if (data.userType == 1) {
                console.log(data.userType, 'data.userType')
                params.userType = 1
                bindStoreFun(params)
              } else if (data.userType == 2){
                bindRoleStoreFun(params)
              }
          } else {
            queryPageStoreFun()
          }
        }
      })
    }
    const switchStore = () => {
      router.push('/storeList')
    }
    // const systemType = getCode().systemType
    // const msgId = getCode().msgId
    // const dramaId = getCode().dramaId
    // if (systemType === 'user') {
    //   if (localStorage.getItem('openId')) {
    //     const storeId = getCode().storeId
    //     window.location.href = `${config.userUrl}?storeId=${storeId}&openId=${localStorage.getItem('openId')}&msgId=${msgId}&dramaId=${dramaId}`
    //   } else {
    //     const AppId = config.appId
    //     const code = getCode().code
    //     const local = window.location.href
    //     if (code == null || code === '') {
    //       console.log('code')
    //       window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
    //     } else {
    //       getOpenId(code)
    //     }
    //   }
    // }
    onMounted(() => {
      // if (route.query.status) {
      //   localStorage.clear()
      // }
      console.log(history.state.scroll)
      if(history.state.scroll===null&&!route.query.status){
        localStorage.clear()
      }
      // localStorage.setItem('openId','o8Lhy6gQURYwricCZ16o-jAYMl3I') // 1001剧本杀openId
      // localStorage.setItem('openId','o8Lhy6mS9EcwBcUcjgvBdsqfoZFo') // 1001剧本杀  十一

      // localStorage.setItem('openId','oozBn6_SS0QzdGm7aUZT1l9oUr9A') // 1001剧本杀  测试环境 AD
      // localStorage.setItem('openId','oozBn6wR7fo2zjpuOSg7xKocrKc4') // 1001剧本杀  测试环境 X

      // 1、判断是否有缓存openId  无getOpenId 获取
      // 2、取得openId后 判断地址中是否有门店Id storeId  无结束
      // 3、判断绑定类型 userType 1 店长 2 店员

      if (localStorage.getItem('openId')) {
        store.commit('app/setOpenId', localStorage.getItem('openId'))
        let data = getCode()
        if (data.storeId) {
          if(localStorage.getItem('storeId')===null){
            localStorage.setItem('storeId', data.storeId)
          }
          const params = {
            // storeId: data.storeId,
            storeId:localStorage.getItem('storeId'),
            headImg: localStorage.getItem('headImg'),
            nickName: localStorage.getItem('nickName')
          }
          if (data.userRoleId) {
            params.userRoleId = data.userRoleId
          }
          if (data.userType == 1) {
            params.userType = 1
            bindStoreFun(params)
          } else if (data.userType == 2) {
            bindRoleStoreFun(params)
          }
          if (data.systemType === 'user') {
              window.location.href = `${config.userUrl}?storeId=${data.storeId}&openId=${localStorage.getItem('openId')}&msgId=${data.msgId}&dramaId=${data.dramaId}`
          }
        } else {
          if (localStorage.getItem('storeId')) {
            getAuth()
          } else {
            queryPageStoreFun()
          }
        }
      } else {
        const AppId = config.appId  // 本地开发
        const code = getCode().code
        const local = window.location.href
        if (code == null || code === '') {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        } else {
          getOpenId(code)
        }
      }
    })
    const columns = [{name:'护照',value:'0'},{name:'身份证',value:'1'},{name:'港澳通行证',value:'2'}];
    const customFieldName = {
      text:'name'
    };
    const route = useRoute()
    const userType = ref(null)
    // 玩家物料二维码
    const router = useRouter()
    const storeName = ref(route.query.storeName)
    const venueName = ref(route.query.venueName)
    const girdList = ref([])
    const showPicker = ref(false);

    const data =  reactive({
      isInput: false
    })
    const formData = reactive({
      name: undefined,
      identityCard: undefined,
      mobile: undefined,
      cardType: '1'
      // comment: undefined
    })
    const navTo = (item) => {
      let url = ''
      switch(item.menuName) {
        case '选角发车':
          url = `/dramaList`
          break
        case '我的账户':
          url = `/open`
          break
        case '角色绑定':
          url = `/glasses?storeId=${localStorage.getItem('storeId')}`
          break
        case '发车管理':
          url = `/activityManagement`
          break
        case '数据统计':
          url = `/statistics`
          break
        case '子账号':
          url = `/accountManagement`
          break
      }
      router.push(url)
    }
    const fullName = ref('')

    // 获取菜单权限
    const getAuth = () => {
      queryByLogin({storeId: localStorage.getItem('storeId')}).then( res => {
        if (res.success) {
          fullName.value = res.data.venueName
          userType.value = res.data.userType
          res.data.menuList.forEach(item => {
            if (item.menuName == '我的账户') {
              item.icon = require('./img/account.png')
            }
            if (item.menuName == '数据统计') {
              item.icon = require('./img/Statistics.png')
            }
            if (item.menuName == '角色绑定') {
              item.icon = require('./img/glasses.png')
            }
            if (item.menuName == '发车管理') {
              item.icon = require('./img/activity.png')
            }
          })
          girdList.value = res.data.menuList
        } else {
          router.replace('/')
        }
      }).catch(err => {
         Notify({ type: 'danger', message: err })
      })
    }
    return {
      // data
      fullName,
      girdList,
      navTo,
      ...toRefs(data),
      ...toRefs(formData),
      columns,
      customFieldName,
      showPicker,
      storeName,
      venueName,
      userType,
      // fn
      switchStore
    }
  }
}
</script>
<style lang="less" scoped>
.store-page {
  position: relative;
  background: url('./img/store-bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-sizing: border-box;
  height: 100vh;
  overflow: auto;
}
header {
  margin-top: 240px;
  margin-bottom: 30px;
  padding: 0 20px;
  .header-title {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    img {
      margin-left: 5px;
      width: 17px;
      height: 15px;
    }
  }
  .store-title {
    font-size: 27px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 38px;
  }
}
main {
  padding: 0 16px;
  .nav-item {
    margin-bottom: 16px;
    padding: 0 12px;
    background: #FFFFFF;
    border-radius: 8px;
    .nav-header {
      display: flex;
      align-items: center;
      padding: 10px 0;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.7);
      img {
        margin-right: 6px;
        width: 4px;
        height: 20px;
      }
    }
    .nav-wrap {
      margin-top: 5px;
      padding-bottom: 20px;
      display: flex;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.7);
      .nav-box {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-bottom: 7px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.title-box {
  .title {
    color: rgb(255, 255, 255);
    font-family: PingFang SC;
    font-size: 32px;
    font-weight: undefined;
    line-height: 47px;
    letter-spacing: 0px;
    text-align: left;
  }
  .tip {
    color: rgba(255, 255, 255, 0.4);
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: undefined;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
.header-title, .item-title {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}
.QR-code {
    margin-top: 5px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    .QR-title {
      color: rgba(0, 0, 0, 0.25);
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: undefined;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
    }
    img{
        width: 210px;
        height: 210px;

    }
}
.nav {
  margin-top: 20px;
  padding: 8px;
  border-radius: 16px;
  background: #fff;
}
.left-warp {
  display: flex;
  align-items: center;
}
</style>
