<template>
	<!-- App.vue内容 -->
  <div class="page"> 
    <router-view />
  </div>
</template>
 
<script>
 
export default {
  name: 'App'
}
</script>
<style scoped>
  .page{
    width: 100%;
    height: 100vh;/* 矩形 */
    background: rgb(238, 238, 238);
  }
</style>
