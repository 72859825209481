<template>
  <div>
    <van-nav-bar
      @click-left="onClickLeft"
    >
      <template #title>
        <div class="title">新增子账号二维码</div>
        <!-- <div class="title">修改管理权限</div> -->
      </template>
      <template #left>
        <van-icon name="arrow-left" color="#323233" size="20" />
      </template>
    </van-nav-bar>
    <main>
        <div class="item-wrap">
            <div class="header-title">管理权限：</div>
            <div class="check-wrap">
              <!-- @change="userChange" -->
                <van-checkbox-group v-model="checked" ref="checkboxGroup" >
                    <van-checkbox v-for="item in checkList" shape="square" :name="item.id" :key="item.id">{{item.menuName}}</van-checkbox>
                </van-checkbox-group>
                <div class="control">
                    <van-button type="primary" size="small" style="margin-right:10px;" @click="checkAll">全选</van-button>
                    <van-button type="primary" size="small" @click="toggleAll">反选</van-button>
                </div>
            </div>
        </div>
    </main>
    <footer>
        <van-button @click="submit" type="primary" size="normal" block>保存</van-button>
    </footer>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { menuQueryAll, userRoleSave, activityQueryOne, userRoleUpdate } from '@request/api'
import { Notify } from 'vant'
// import store from '@/store'
export default {
  setup(){
    const checked = ref([])
    const checkboxGroup = ref(null)
    const activityId = ref(null)
    const checkList = ref([])
    const checkAll = () => {
      checkboxGroup.value.toggleAll(true);
    }
    const toggleAll = () => {
      checkboxGroup.value.toggleAll();
    }
    // const userChange = (event) => {
    //   console.log(event)
    // }
    const router = useRouter()
    const route = useRoute()
    const onClickLeft = () => {
        router.back()
    }
    const addUser = (data) => {
      userRoleSave(data).then(res => {
        if (res.success) {
          Notify({ type: 'success', message: '新增子账号二维码成功！' })
        } else {
          Notify({ type: 'danger', message: res.msg })
        }
      })
    }
    const updateUser = (data) => {
      userRoleUpdate(data).then(res => {
        if (res.success) {
          Notify({ type: 'success', message: '修改成功！' })
        }
      })
    }
    const submit = () => {
      const data = {
        storeId: localStorage.getItem('storeId'),
        menuIdList: []
      }
      if (!checked.value.length) {
        Notify({ type: 'warning', message: '请选择权限！' })
        return
      }
      checked.value.forEach(check => {
        checkList.value.forEach(item => {
           if (check === item.id) {
             data.menuIdList.push(item.id)
           }
        })
      })
      if (activityId.value) {
        data.id = activityId.value
        updateUser(data)
      } else {
        addUser(data)
      }
    }
    const getActivity = () => {
      const data = {
        storeId: localStorage.getItem('storeId'),
        id: route.query.id
      }
      if(localStorage.getItem('storeId')){
        activityQueryOne(data).then(res => {
          if (res.success) {
            const data = res.data.menuList
            activityId.value = res.data.id
            data.forEach(item => {
              checked.value.push(item.id)
            })
          }
        })
      }

    }
    onMounted(() => {
      menuQueryAll().then(res => {
        if (res.success) {
          checkList.value = res.data
        }
      })
      if (route.query.id) {
        getActivity()
      }
    })
    return {
      onClickLeft,
      submit,
      checked,
      checkAll,
      toggleAll,
      checkboxGroup,
      checkList,
      // userChange
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
.action {
    font-size: 14px;
    color: #1989fa;
    font-weight: normal;
}
header {
    padding: 10px 15px;
}
.header-title, .item-title {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}
main {
    .item-wrap {
        background: #fff;
        margin-top: 10px;
        padding: 10px 15px;
        font-size: 14px;
        color: #666;
    }
}
.QR-code {
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #666;
    border-bottom: 1px solid #eee;
    img{
        margin-bottom:5px;
        width: 150px;
        height: 150px;

    }
}
.item-footer{
    padding: 5px 0;
    display: flex;
    align-items: center;
}
.user-logo {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.user-wrap {
    text-align: center;
    font-size: 12px;
    color: #1989fa;
}
footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding:15px;
}
.check-wrap {
    padding: 10px 15px;
    /deep/ .van-checkbox {
        margin-bottom: 10px;
    }
}
.control {
    margin-top: 10px;
}
</style>
