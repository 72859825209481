<style lang="less" scoped>
    .activity-page {
      overflow: auto;
      background: rgb(238, 238, 238);
    }
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #666;
  }
  .action {
      font-size: 14px;
      color: #1989fa;
      font-weight: normal;
  }
  main {
    padding: 0 16px 16px;
    .user-wrap {
        padding-bottom: 16px;
        border-radius: 8px;
        background: #fff;
        .user-header {
            display: flex;
            justify-content: space-between;
            padding: 10px 16px;
            .user-header-title {
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
            }
            .user-num {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                line-height: 22px;
                color: #1D1DD8;
            }
        }
    }
  }
  .user-list {
    padding-left: 16px;
    .list-item {
        display: flex;
        align-items: center;
        &:last-child {
            .list-item-right {
                border: none;
            }
        }
        img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
        }
        .list-item-right {
            padding: 16px 16px  16px 0;
            flex: 1;
            margin-left: 10px;
            border-bottom:  1px solid rgba(0,0,0,0.1);
        }
        .list-item-top {
            display: flex;
            justify-content: space-between;
            .top-left {
                flex: 1;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0,0,0,0.85);
            }
            .top-right {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0,0,0,0.5);
            }
        }
        .list-item-btm {
            display: flex;
            justify-content: space-between;
            .btm-left {
                flex: 1;
                line-height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1D1DD8;
            }
            .btm-right {
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0,0,0,0.25);
                line-height: 16px;
            }
        }
    }
  }
  .prepare-header {
    display: flex;
    align-items: center;
    img {
      margin-right: 16px;
      width: 75px;
      height: 120px;
      border-radius: 4px;
    }
    .prepare-header-right {
      flex: 1;
      .status {
        text-align: right;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1DD8;
        line-height: 20px;
      }
      .prepare-title {
        height: 29px;
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 29px;
      }
      .prepare-tip {
        padding-bottom: 9px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.5);
        line-height: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }
      .prepare-time {
        margin-top: 9px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.5);
        line-height: 20px;
      }
    }
  }
  .in-num-wrap {
    padding: 4px 0;
    text-align: right;
    .in-num-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 20px;
    }
    .in-num {
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D1DD8;
      line-height: 29px;
    }
  }
  .prepare-main{
    display: flex;
    flex-wrap: wrap;
    .user-wrap {
      margin-bottom: 12px;
      padding: 8px 12px px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 97px;
      height: 132px;
      background: #FFFFFF;
      box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      box-sizing: border-box;
      .user-title {
        margin-bottom: 4px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.25);
        line-height: 16px;
      }
      .user-name {
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 18px;
      }
      .user-role {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1DD8;
        line-height: 16px;
      }
      img {
        margin-bottom: 4px;
        width: 54px;
        height: 54px;
        border-radius: 100%;
      }
    }
  }
  .prepare-footer {
    display: flex;
    padding: 16px 0 0;
    .btn-left {
      width: 77px;
      height: 40px;
      border-radius: 4px;
      color: rgba(0,0,0,0.5);
      border: 1px solid rgba(0,0,0,0.5);
    }
    .btn-right {
      flex: 1;
      margin-left: 12px;
      height: 40px;
      background: #1D1DD8;
      border-radius: 4px;
    }
  }
  .follow-header {
    height: 40px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    .follow-num{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
    }
    .follow-status {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D1DD8;
    }
  }
  .follow-main {
    background: #fff;
    padding: 12px 16px 16px;
    border-radius: 8px;
  }
  .follow-footer {
    padding: 0 16px 16px;
    text-align: right;
    .follow-btn {
      width: 77px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      border: 1px solid rgba(0,0,0,0.5);
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
    }
  }
  .header {
      display: flex;
      padding: 10px 0;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;
      align-items: center;
  }
  .main {
      padding: 10px 0;
      border-bottom: 1px solid #e8e8e8;
  }
  .main div{
    line-height: 30px;
  }
  .footer {
      padding-top: 10px;
      text-align: right;
  }
  header {
    padding-top: 8px;
    height: 250px;
    background: linear-gradient(180deg, #D2D2D2 0%, #EEEEEE 100%);
    box-sizing: border-box;
    /deep/ .van-nav-bar {
      background:transparent;
    }
    /deep/ .van-hairline--bottom:after {
      border: none;
    }
    .page-title {
      margin-top: 9px;
      padding-left: 16px;
      color: #fff;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 33px;
    }
  }
  .back-title {
    margin-left: 8px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 29px;
  }
  .play-time {
    margin-bottom: 14px;
    padding-left: 52px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D1DD8;
    line-height: 20px;
  }
  .card {
    padding: 0 16px 16px;
  }
  .play-wrap {
    margin-top: 16px;
    background: #fff;
    border-radius: 8px;
    .play-header {
        padding: 10px 16px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
    }
    .play-list {
        padding-left: 16px;
        padding-bottom: 12px;
    }
    .play-item {
        padding: 12px 16px 12px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        &:last-child {
            border: none;
        }
    }
    .play-item-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.85);
    }
    .play-item-con {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.5);
    }
  }
  footer {
    padding: 0 16px 32px;
    .over {
        height: 44px;
        border-radius: 8px;
        border: 1px solid #1D1DD8;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1DD8;
    }
  }
  </style>
  <template>
    <div class="activity-page" v-if="detailData">
      <header>
        <van-nav-bar
          @click-left="onClickLeft"
        >
          <template #left>
            <van-icon name="arrow-left" color="rgba(0,0,0,0.85)" :size="32" />
            <span class="back-title" v-if="detailData.status === 1">发车中</span>
            <span class="back-title" v-else>已结束</span>
          </template>
        </van-nav-bar>
        <div class="play-time">发车时长 {{detailData.timeLength}}分</div>
        <section class="card">
            <div class="prepare-header follow-main">
                <img :src="`${configUrl.imgRoot}${detailData.dramaImg}`" alt="">
                <div class="prepare-header-right">
                <div class="prepare-title">{{detailData.dramaName}}</div>
                <div class="prepare-tip">{{detailData.typeName}} · {{detailData.roleNum}}人</div>
                <div class="prepare-time">发车时间 {{detailData.beginTime}}</div>
                </div>
            </div>
        </section>
      </header>
    <main v-if="detailData">
      <section class="user-wrap">
        <div class="user-header">
            <div class="user-header-title">玩家信息</div>
            <div class="user-num">{{detailData.roleUserInfoVoList.length > detailData.roleNum ? detailData.roleNum : detailData.roleUserInfoVoList.length}}人</div>
        </div>
        <div class="user-list">
          <template v-for="(item, index) in detailData.roleUserInfoVoList" :key="item.id">
            <div class="list-item" v-if="index < detailData.roleNum" >
              <img :src="item.headImg" alt="">
              <div class="list-item-right">
                  <div class="list-item-top">
                      <div class="top-left">{{item.nickName}}</div>
                      <div class="top-right">{{item.machineNum}}号设备</div>
                  </div>
                  <div class="list-item-btm">
                      <div class="btm-left">{{item.roleName}}</div>
                      <div class="btm-right">物理编号：{{item.machineSn}}</div>
                  </div>
              </div>
            </div>
          </template>
        </div>
      </section>
      <section class="play-wrap">
         <div class="play-header">开本发车信息</div>
         <div class="play-list">
            <div class="play-item">
                <div class="play-item-title">发车单号</div>
                <div class="play-item-con">{{detailData.orderNo}}</div>
            </div>
            <div class="play-item">
                <div class="play-item-title">开本时间</div>
                <div class="play-item-con">{{detailData.createTime}}</div>
            </div>
            <div class="play-item">
                <div class="play-item-title">发车时间</div>
                <div class="play-item-con">{{detailData.beginTime}}</div>
            </div>
            <div class="play-item">
                <div class="play-item-title">支付状态</div>
                <div class="play-item-con">{{detailData.isPayed == 1 ? '已支付' : '未支付'}}</div>
            </div>
            <div class="play-item">
                <div class="play-item-title">{{detailData.isPayed == 1 ? '实付金额' : '应付金额'}}</div>
                <div class="play-item-con">¥{{detailData.payAmount}}</div>
            </div>
            <div class="play-item">
                <div class="play-item-title">结束时间</div>
                <div class="play-item-con">{{detailData.endTime}}</div>
            </div>
         </div>
      </section>
    </main>
    <van-empty v-else image="error" description="暂无数据" />
    <footer v-if="detailData.status === 1">
        <van-button class="over" @click="end(detailData.activeId)" size="large">结束</van-button>
    </footer>
    </div>
  </template>
  <script>
  import { ref } from '@vue/reactivity'
  import { useRouter, useRoute } from 'vue-router'
  import { activityEnd, getDramaActiveInfoApi } from '@request/api'
  import { Notify } from 'vant'
  import _ from 'lodash'
  import config from '@/config.js'
  export default {
    data(){
      return {
        configUrl: config
      }
    },
    setup(){
      const router = useRouter()
      const route = useRoute()
      const detailData = ref(null)
      const getDramaActiveInfoFn = () => {
        getDramaActiveInfoApi(route.query.id).then((res) => {
          if (res.success) {
            detailData.value = res.data
          }
        })
      }
      getDramaActiveInfoFn()
      const onClickLeft = () => {
          router.back()
      }
      const getTime = (begin, end) => {
        let eTime
        if (!end) {
          eTime = new Date().getTime()
        } else {
          eTime = new Date(end.replace(/-/g,"/")).getTime()
          console.log('eTime',eTime);
        }
        const diff= eTime - new Date(begin.replace(/-/g,"/")).getTime()
        console.log('diff',diff);
        if (Math.floor(diff/(3600*1000))) {
          return `${Math.floor(diff/(3600*1000))} 时${Math.floor(diff/(60*1000))%60} 分钟`
        } else {
          return `${Math.floor(diff/(60*1000))%60}分`
        }
      }
      const end = _.throttle((id) => {
        activityEnd({id}).then(res => {
          if (res.success) {
            Notify({
              type: 'success',
              message: '已结束'
            })
            getDramaActiveInfoFn()
          }
        })
      }, 500)
      return {
        // data
        detailData,
        // fn
        end,
        onClickLeft,
        getTime
      }
    }
  }
  </script>
