<style lang="less" scoped>
.glasses-page {
  background: #EEEEEE;
}
.add-dia {
  /deep/ .van-dialog__header {
    padding: 0;
  }
  /deep/ .van-dialog {
    background: #EEEEEE;
  }
  /deep/ .van-sidebar-item--select:before {
    display: none;
  }
  /deep/ .van-sidebar-item--select {
    color: #1D1DD8;
  }
  /deep/ .van-tree-select__item--active {
    color: #1D1DD8;
  }
  /deep/ .van-tree-select__content {
    flex: 1;
  }
}
.popup-content {
  padding: 10px 15px;
  .popup-header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;
  }
}
header {
  height: 120px;
  padding: 16px 0;
  background: url('./img/glasses-bg.png');
  background-size:cover;
  box-sizing: border-box;
  /deep/ .van-nav-bar {
    background:transparent;
  }
  /deep/ .van-hairline--bottom:after {
    border: none;
  }
  .page-title {
    margin-top: 9px;
    padding-left: 16px;
    color: #fff;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 33px;
  }
}
main { 
  padding: 0 16px;
  overflow: auto;
}
.top {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0,0,0,0.5);
  .top-left {

  }
  .top-right {
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D1DD8;
    align-items: center;
  }
  img{
    margin-left: 5px;
    display: block;
    width: 9px;
    height: 16px;
  }
} 
.item-wrap {
    position: relative;
    border-radius: 8px;
    font-size: 14px;
    color: #666;
    background: #fff;
    margin-bottom: 16px;
    .status {
      position: absolute;
      right: 16px;
      top: 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.25);
      line-height: 20px;
    }
    .active {
      color: #1D1DD8;
    }
    .item-header {
      height: 117px;
      padding: 24px 24px 21px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      img {
        width: 72px;
        height: 72px;
        border-radius: 8px;
      }
      .header-right {
        margin-left: 24px;
      }
      .right-title {
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 25px;
        img.icon {
          margin-left: 12px;
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 0;
        }
      }
      .right-con {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.25);
        line-height: 17px;
      }
    }
  .item-main {
    .item-main-title {
      padding: 12px 16px 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.5);
      line-height: 20px;
    }
    .item-main-con {
      padding-left: 16px;
    }
    .drama-role {
      height: 47px;
      padding-right: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      .drama-title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
      }
      .drama-action {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1DD8;
      }
      &:last-child {
        border: none;
      }
    }
  }
  .item-footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D1DD8;
    img {
      margin-right: 8px;
      display: block;
      width: 19px;
      height: 19px;
    }
  }
}
.dia-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  .close {
    text-align: right;
  } 
  .dia-title {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    line-height: 33px;
  }
}
.dia-footer {
  padding: 16px 16px 32px;
  /deep/ .van-button {
    border-radius: 8px;
  }
  /deep/ .van-button__text { 
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.unbinding {
  /deep/ .van-dialog__header {
    padding: 0;
  }
  .unbinding-con {
    padding: 32px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 8px;
      width: 72px;
      height: 72px;
      border-radius: 8px;
    }
    .unbinding-title {
      margin-bottom: 8px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0,0,0,0.85);
      line-height: 22px;
    }
    .unbinding-tip {
      margin-bottom: 2px;
      height: 29px;
      font-size: 21px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      line-height: 29px;
    }
    .unbinding-mes {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D1DD8;
      line-height: 20px;
    }
  }
  .unbinding-footer {
    padding: 0px 16px 24px;
    display: flex;
    justify-content: space-between;
    /deep/ .van-button {
      width: 136px;
      height: 44px;
      background: #EBEBEB;
      border-radius: 8px;
    }
    .cancel {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
    }
  }
}
</style>
<template>
  <div class="glasses-page">
    <header>
      <van-nav-bar
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" color="#fff" :size="32" />
        </template>
      </van-nav-bar>
      <div class="page-title">角色眼镜绑定</div>
    </header>
    <main v-if="glassesList && glassesList.length">
      <div class="top">
        <div class="top-left">AR眼镜数量：{{glassesList.length}}</div>
        <div class="top-right"><div @click="bindFn">一键绑定角色</div><img src="./img/right.png" alt=""></div>
      </div>
      <div class="item-wrap" v-for="(item, index) in glassesList" :key="index">
        <div class="status active">已就绪</div>
        <div class="item-header" @click="handle(item)">
            <div class="header-left">
              <img src="./img/glasses.png" alt="">
            </div>
            <div class="header-right">
              <div class="right-title">
                <div>眼镜编号：{{item.num}}</div>
                <img class="icon  " src="./img/edit.png" alt="">
              </div>
              <div class="right-con">物理编号：{{item.physicalNumber}}</div>
            </div>
        </div>
        <div class="item-main" v-if="item.dramaVoList && item.dramaVoList.length > 0" >
          <div class="item-main-title">已绑定角色</div>
          <div class="item-main-con">
            <div class="drama-role" v-for="drama in item.dramaVoList" :key="drama.id">
              <div class="drama-title">{{drama.dramaName}}- {{drama.dramaRoleList[0].roleName}}</div>
              <div class="drama-action" @click="delRole(drama, item.num )">解绑</div>
            </div>
          </div>
        </div>
        <div class="item-footer" @click="addRole(item.id)">
          <img src="./img/add.png" alt="">
          <div>新增绑定角色</div>
        </div>
      </div>
    </main>
    <van-empty v-else image="error" description="暂无数据" />
    <div class="add-dia">
      <van-dialog v-model:show="show" :show-cancel-button="false">
        <template #title>
          <div class="dia-header">
            <div class="dia-title">新增绑定角色</div>
            <div class="close" @click="show = false"><van-icon name="cross" color="#787878" :size="36"/></div>
          </div>
        </template>
        <div class="popup-content" v-if="items">
          <van-tree-select
            v-model:active-id="activeId"
            v-model:main-active-index="activeIndex"
            :items="items"
          />
        </div> 
        <template #footer>
          <div class="dia-footer"> 
            <van-button @click="submit" color="#1D1DD8" size="large">确认</van-button>
          </div>
        </template>
      </van-dialog>
    </div>
    <div class="unbinding">
      <van-dialog v-model:show="showUnbinding" :show-cancel-button="false">
        <template #title>
        </template>
        <div class="unbinding-con">
          <img src="./img/glasses.png" alt="">
          <div class="unbinding-title">眼镜编号：{{unbindingData.num}}</div>
          <div class="unbinding-tip">确认解绑该角色</div>
          <div class="unbinding-mes">{{unbindingData.name}}</div>
        </div> 
        <template #footer>
          <div class="unbinding-footer"> 
            <van-button class="cancel" @click="showUnbinding = false" color="#EBEBEB ">取消</van-button>
            <van-button class="submit" @click="UnbindingFn" color="#1D1DD8" >确认</van-button>
          </div>
        </template>
      </van-dialog>
    </div>
  </div>
</template> 
<script>
import { reactive, toRefs, ref } from '@vue/reactivity' 
import { onMounted } from '@vue/runtime-core'
import { useRouter, useRoute } from 'vue-router'

import { queryGlass, glassRoleRel, glassRoleRelDelApi, getNoBindDrama } from '@request/api'
import { Notify } from 'vant'
import store from '@/store'
export default {
  setup(){
    const DramaList = ref(null)
    const data =  reactive({
      isInput: false
    })
    const activeId = ref(null);
    const activeIndex = ref(0);
    const items = ref(null)
    const show = ref(false)
    const glassesList = ref(null)
    const formData = reactive({
      name: undefined,
      identityCard: undefined,
      mobile: undefined,
      cardType: '1'
      // comment: undefined
    })
    const router = useRouter()
    const route = useRoute()
    const onClickLeft = () => {
        router.back()
    }
    const bindFn = () => {
      router.push('/binding')
    }
    const handle = (item) =>{
      store.commit('app/setGlasses', item)
      router.push('/addGlasses')
    }
    const queryGlassFun = () => {
      let data = {
        storeId: route.query.storeId,
        curPage: 1,
        pageSize: 100
      }
      queryGlass(data).then(res => {
        if (res.success) {
          glassesList.value = res.data.records
        }
      })
    }
    const glassId = ref(null)
    const submit = () => {
      console.log(activeIndex.value)
      glassRoleRel(
        {
          dramaId: DramaList.value[activeIndex.value].id,
          dramaRoleId:activeId.value,
          glassMachineId: glassId.value,
          storeId: route.query.storeId,
        }
      ).then(res => {  
        if (res.success) {
          Notify({ type: 'success', message: '绑定成功！' })
          queryGlassFun()
          activeId.value = null
          show.value = false
        }
      })
    }
    //  解绑模块
    const showUnbinding = ref(false)
    const unbindingData = ref(null)
    const delRole = (item, num) => {
      showUnbinding.value = true
      unbindingData.value = {
        ...item,
        num,
        name: `${item.dramaName}-${item.dramaRoleList[0].roleName}`
      }
    }
    const UnbindingFn = () => {
      showUnbinding.value = false
      console.log(unbindingData.value)
      glassRoleRelDelApi(unbindingData.value.dramaRoleList[0].glassRoleRelId).then((res) => {
        if (res.success) {
          Notify({ type: 'success', message: '解绑成功！' })
          queryGlassFun()
        }
      })
    }
    
    const getNoBindDramaFn = (machineId) => {
      getNoBindDrama(machineId).then((res) => {
        let arr = res.data 
          let handleData = []
          DramaList.value = arr
          arr.forEach(item =>  {
            let obj = {}
            obj.text = item.dramaName
            obj.children = []
            item.dramaRoleList.forEach(role => {
              obj.children.push({ text: role.roleName, id: role.id, disabled: role.status === 1 ? true : false})
            })
            handleData.push(obj)
          }) 
          items.value = handleData
      })
    }
    // 新增角色绑定
    const addRole = (id) => {
      getNoBindDramaFn(id)
      show.value = true
      glassId.value = id
    }
    onMounted(() => {
      queryGlassFun()
    })
    return {
      // data
      showUnbinding,
      unbindingData,
      // fn
      UnbindingFn,
      DramaList,
      show,
      glassesList,
      onClickLeft,
      handle,
      ...toRefs(data),
      ...toRefs(formData),
      submit,
      addRole,
      items,
      activeId,
      activeIndex,
      delRole,
      // fn
      bindFn,
      getNoBindDramaFn
      
    }
  }
}
</script>