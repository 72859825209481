<style lang="less" scoped>
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
main {
  padding: 0 15px;
  background: #fff;
}
.item-wrap {
  padding: 8px;
  border-bottom: 1px solid #e8e8e8;
  &:last-child{
    border-bottom: none;
  }
}
.header, .footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}
.footer {
  font-size: 12px;
  color: #888;
}
.red {
  font-size: 16px;
  color: red;
}
.green {
  font-size: 16px;
  color: #3ddb77;
}
</style>
<template>
  <div>
    <van-nav-bar
      @click-left="onClickLeft"
    >
      <template #title>
        <div class="title">资金流水明细</div>
      </template>
      <template #left>
        <van-icon name="arrow-left" color="#323233" size="20" />
      </template>
    </van-nav-bar>
    <main v-if="flowList&&flowList.length>0">
        <div class="item-wrap" v-for="(item, index) in flowList" :key="index">
          <div class="header">
            <div>
              <span v-if="item.opeType == 'ADD'">收入</span>
              <span v-else>支出</span>({{item.description}})</div>
            <div :class="item.opeType == 'ADD'? 'red': 'green'">
              <span  v-if="item.opeType == 'ADD'">+</span>
              <span v-else></span>{{item.amount/100}}</div>
          </div>
          <div class="footer">
            <div>{{item.updateTime}}</div>
            <div >余额：<span style="font-weight: bold; font-size: 16px ">{{item.account/100}}</span></div>
          </div>
        </div>
    </main>
    <van-empty v-else description="暂无数据" />
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { financialFlow } from '@request/api'
// import store from '@/store'
export default {
  setup(){
    const checked = ref([]);
    const checkboxGroup = ref(null);
    const flowList = ref(null)
    const checkAll = () => {
      checkboxGroup.value.toggleAll(true);
    }
    const toggleAll = () => {
      checkboxGroup.value.toggleAll();
    }

    const router = useRouter()
    const onClickLeft = () => {
        router.back()
    }
    const submit = () => {
    }
    onMounted(() => {
      let data = {
        curPage: 1,
        pageSize: 10,
        storeId: localStorage.getItem('storeId')
      }
      if(localStorage.getItem('storeId')){
        financialFlow(data).then(res => {
          if (res.success) {
            flowList.value = res.data.records
          }
          console.log(res)
        })
      }
    })
    return {
      flowList,
      onClickLeft,
      submit,
      checked,
      checkAll,
      toggleAll,
      checkboxGroup,

    }
  }
}
</script>
