import { createApp } from 'vue'
import App from './App.vue'
import { Button, NavBar, Icon, Cell,
     CellGroup, Grid, GridItem, Field,
     Col, Row, Notify, Dialog, Collapse,
     CollapseItem, Picker, Popup, RadioGroup,
     Radio, DropdownMenu, DropdownItem, ActionSheet,
     Cascader, Pagination, Tab, Tabs, Uploader, Loading,
     TreeSelect, List, PullRefresh, Empty, Form,
     Checkbox, CheckboxGroup, Calendar, DatetimePicker, Switch  } from 'vant';
import router from './router'
import 'vant/lib/index.css'; // 全局引入样式
import './assets/css/common.less'
import store from './store'
import 'amfe-flexible'





const app = createApp(App) // 创建实例

import moment from "moment";
app.config.globalProperties.$moment = moment

app.use(Button).use(NavBar).use(Switch).use(Icon).use(Cell).use(CellGroup).use(Grid).use(GridItem).use(Field).use(Col)
     .use(Row).use(Notify).use(Dialog).use(Collapse).use(CollapseItem).use(Picker).use(Cascader).use(Popup)
     .use(RadioGroup).use(Radio).use(DropdownMenu).use(DropdownItem).use(ActionSheet).use(Pagination).use(Tab)
     .use(Tabs).use(Uploader).use(Loading) .use(Checkbox).use(CheckboxGroup).use(TreeSelect).use(store)
     .use(List).use(PullRefresh).use(Empty).use(Form).use(Calendar).use(DatetimePicker).use(router).mount('#app')