import app from './modules/app' 
import {
    createStore
} from 'vuex'

export default createStore({
    namespaced: true,
    modules: {
        app
    },
    state: {

    },
    mutations: {

    },
    actions: {

    }
})
