<style lang="less" scoped>
.account-page {
  overflow: auto;
  background: rgb(238, 238, 238);
  /deep/ .van-dialog__header {
    padding: 0;
  }
}
.action {
    margin-left: 10px;
    font-size: 14px;
    color: #1989fa;
    font-weight: bold;
}
header {
  height: 120px;
  padding: 16px 0;
  background: url('./img/header-bg.png');
  background-size:cover;
  box-sizing: border-box;
  /deep/ .van-nav-bar {
    background:transparent;
  }
  /deep/ .van-hairline--bottom:after {
    border: none;
  }
  .page-title {
    margin-top: 9px;
    padding-left: 16px;
    color: #fff;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 33px;
  }
}
.header-title, .item-title {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}
main {
  padding: 16px;
    .item-wrap {
        margin-bottom: 20px;
        background: #fff;
        border-radius: 8px;
        font-size: 14px;
        color: #666;
        .custom-title {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0,0,0,0.8);
          line-height: 25px;
        }
        .custom-content {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D1DD8;
          line-height: 20px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      /deep/ .van-cell {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 16px;
      }
      /deep/ .van-cell__right-icon {
        font-size: 24px;
      }
      /deep/ .van-cell:after {
        display: none;
      }
    }
}
.qrcode {
  padding: 30px;
  text-align: center;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.item-footer{
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.user-logo {
    width: 54px;
    height: 54px;
    border-radius: 100%;
}
.user-wrap {
  position: relative;
  width: 76px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0,0,0,0.85);
  line-height: 20px;
}
.user-name {
  margin-top: 4px;
}
.user-del {
  position: absolute;
  top: -3px;
  right: 9px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #1D1DD8;
  line-height: 18px;
  text-align: center;
}
footer{
    padding:4px 16px 32px;
    .footer-btn {
      height: 44px;
      line-height: 44px;
      border-radius: 8px;
      border: 1px dashed#808080;
      text-align: center;
    }
    span {
      margin-left: 10px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #808080;
    }
}
.dia-header {
  padding: 20px;
  height:108px;
  background: #E2E2E2;
  box-sizing: border-box;
  .close {
    text-align: right;
  }
  .dia-title {
    text-align: left;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    line-height: 33px;
  }
}
.check-wrap {
  padding: 0 16px;
  .check-item {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    &:last-child {
      border: none;
    }
  }
  .check-left {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.8);
  }
}
.dia-footer {
  padding: 16px 16px 32px;
  /deep/ .van-button {
    border-radius: 8px;
  }
  /deep/ .van-button__text {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>
<template>
  <div class="account-page">
    <header>
      <van-nav-bar
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" color="#fff" :size="32" />
        </template>
      </van-nav-bar>
      <div class="page-title">子账号管理</div>
    </header>
    <main v-if='userList && userList.length'>
        <div class="item-wrap" v-for="item in userList" :key="item.id">
            <van-cell @click="handle(item)" is-link center>
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <span class="custom-title">权限管理</span>
              </template>
              <template #label>
                <span class="custom-content" v-for="(menu, index) in item.menuList" :key="menu.id">{{menu.menuName}}{{index != item.menuList.length-1 ? '、' : ''}}</span>
              </template>
            </van-cell>
            <div class="qrcode">
              <qrcode-vue :value="`${qrCodeUrl}${item.id}`" :size="160" level="H" />
              <!-- <div>{{`${qrCodeUrl}${item.id}`}}</div> -->
            </div>
            <div class="item-footer" v-if="item.userInfoList && item.userInfoList.length">
              <div class="user-wrap" v-for="(user, index) in item.userInfoList" :key="index">
                  <div class="user-del" @click="unbind(user)"><van-icon name="minus" color="#fff" style="font-weight: bold;" /></div>
                  <img class="user-logo" :src="user.headImg" alt="">
                  <div class="user-name">{{user.nickName}}</div>
              </div>
            </div>
        </div>
    </main>
    <van-empty v-else image="error" description="暂无数据" />
    <footer>
      <div class="footer-btn"  @click="handle">
        <van-icon name="plus" color="#808080" />
        <span>新增子账号二维码</span>
      </div>
    </footer>
    <van-dialog v-model:show="show" :show-cancel-button="false">
      <template #title>
        <div class="dia-header">
          <div class="close" @click="close"><van-icon name="cross" color="#787878" :size="36"/></div>
          <div class="dia-title" v-if="activityId">修改子账号权限</div>
          <div v-else class="dia-title">新建子账号</div>
        </div>
      </template>
      <div class="check-wrap" v-if="checkList&&checkList.length>0">
          <div class="check-item" v-for="item in checkList" :key="item.id">
            <div class="check-left">{{item.menuName}}</div>
            <div class="check-right">
              <van-switch v-model="item.checked" active-color="#1d1dd8" size="24px" inactive-color="#dcdee0" />
            </div>
          </div>
      </div>
      <template #footer>
        <div class="dia-footer">
          <van-button @click="submit" v-if="activityId" color="#1D1DD8" size="large">确认修改</van-button>
          <van-button @click="submit" v-else color="#1D1DD8" size="large">创建子账号</van-button>
        </div>
      </template>
    </van-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {
  userRoleQueryAll,
  bindRoleDeleteStore,
  menuQueryAll,
  activityQueryOne,
  userRoleSave,
  userRoleUpdate
} from '@request/api'
import { Notify } from 'vant'
import QrcodeVue from 'qrcode.vue'
// import store from '@/store'
import config from '@/config'
import { Dialog } from 'vant'
export default {
  components: {
    QrcodeVue
  },
  setup(){
    const customFieldName = {
      text:'name'
    };
    const showPicker = ref(false)
    const userList = ref(null)
    const qrCodeUrl = ref(null)
  // 玩家物料二维码
    qrCodeUrl.value =`${config.mchUrl}?userType=2&storeId=${localStorage.getItem('storeId')}&userRoleId=`
    const onConfirm = (value) => {
      formData.cardType = value.value
      showPicker.value = false
    };

    const data =  reactive({
      isInput: false
    })
    const formData = reactive({
      name: undefined,
      identityCard: undefined,
      mobile: undefined,
      cardType: '1'
      // comment: undefined
    })
    const router = useRouter()
    const onClickLeft = () => {
        router.back()
    }
    const show = ref(false)
    const activityId = ref(null)
    // 根据ID获取
    const getActivity = (id) => {
      const params = {
        storeId: localStorage.getItem('storeId'),
        id
      }
      if(localStorage.getItem('storeId')){
        activityQueryOne(params).then(res => {
          if (res.success) {
            const resData = res.data.menuList
            checkList.value.forEach(check => {
              resData.forEach(item => {
                if (item.id == check.id) {
                  check.checked = true
                }
              })
            })
          }
        })
      }

    }
    const close = () => {
      show.value = false
      activityId.value = null
      checkList.value.forEach(item => {
        item.checked = false
      })
    }
    const handle = (item) =>{
      show.value = true
      if (item.id) {
        activityId.value = item.id
        getActivity(item.id)
      }
    }
    const unbind = (user) => {
      Dialog.confirm({
        title: '提示',
        message: '确定解除该子账号绑定吗？',
      })
        .then(() => {
           bindRoleDeleteStore(user.userRoleRelId).then(res => {
            if (res.success) {
              Notify({ type: 'success', message: '解绑成功！' })
              getUserList()
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    }
    const getUserList = () => {
      if(localStorage.getItem('storeId')){
        userRoleQueryAll({ storeId: localStorage.getItem('storeId')})
            .then(res => {
              if (res.success) {
                userList.value = res.data
              } else {
                Notify({ type: 'danger', message: res.msg })
              }
            })
      }

    }
    const checkList = ref([])
    const checked = ref(true)
    // 菜单列表
    const getMenuList = () => {
      menuQueryAll().then(res => {
        if (res.success) {
          res.data.forEach((item) => {
            item.checked = false
          })
          checkList.value = res.data
        }
      })
    }
    const addUser = (data) => {
      userRoleSave(data).then(res => {
        if (res.success) {
          Notify({ type: 'success', message: '新增子账号二维码成功！' })
        } else {
          Notify({ type: 'danger', message: res.msg })
        }
        close()
        getUserList()
      })
    }
    const updateUser = (data) => {
      userRoleUpdate(data).then(res => {
        if (res.success) {
          Notify({ type: 'success', message: '修改成功！' })
        }
        close()
        getUserList()
      })
    }
    const submit = () => {
      const params = {
        storeId: localStorage.getItem('storeId'),
        menuIdList: []
      }
      checkList.value.forEach(item => {
        if (item.checked) {
          params.menuIdList.push(item.id)
        }
      })
      if (!params.menuIdList.length) {
        Notify({ type: 'warning', message: '请选择权限！' })
        return
      }
      if (activityId.value) {
        params.id = activityId.value
        updateUser(params)
      } else {
        addUser(params)
      }
    }
    onMounted(() => {
      getUserList()
      getMenuList()
    })
    return {
      //data
      activityId,
      checkList,
      checked,
      // fn
      close,
      onClickLeft,
      handle,
      submit,
      ...toRefs(data),
      ...toRefs(formData),
      customFieldName,
      onConfirm,
      showPicker,
      userList, // 列表
      qrCodeUrl,
      unbind,
      show
    }
  }
}
</script>
