<template>
  <div class="store-page">
    <van-nav-bar
      title="发车成功"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="wrap">
      <div class="box">
        <div class="img-box">
          <img src="./img/success.png" alt="">
          <div>发车成功</div>
        </div>
        <footer>
          <van-button type="primary" color="#20B2E0" @click="goActiveDetail" block>查看发车</van-button>
          <van-button style="margin-left: 20px;" type="primary"  plain color="#20B2E0" @click="back" block>返回首页</van-button>
        </footer>
      </div>
    </div>
  </div>
</template> 
<script>
// import { reactive, toRefs, ref  } from 'vue' 
// useRoute
import { useRouter, useRoute } from 'vue-router'
export default {
  //导入组件
  components: {
    // QrcodeVue
  },
  setup(){
    const onClickLeft = () => history.back()
    const router = useRouter()
    const route = useRoute()
    const back = () => {
      router.replace('/')
    }
    const goActiveDetail = () => {
      router.push(`/activeDetail?id=${route.query.activeId}`)
    }
    
    return {
      onClickLeft,
      back,
      goActiveDetail
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  padding: 20px 16px;
}
.box {
  background: #FFFFFF;
  border-radius: 8px;
}
.img-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 36px;
  font-size: 21px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
  img {
    width: 96px;
    height: 96px;
    margin-bottom: 14px;
  }
}
footer {
  padding: 20px 30px 24px;
  display: flex;
  border-top: 1px solid #D2D2D2;
  /deep/ .van-button {
    border-radius: 8px;
  }
}

</style>