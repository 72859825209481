const config = {
    // img
    // mode: 'test',
    mode: 'pro',
    imgRoot: 'http://file.xr1001.com/jbs/',
    // dev
    // userUrl:'http://192.168.0.110:8081',
    // mchUrl:'',
    // appId:'wx72a79fc3908d4d48',
    // test
    // userUrl:'http://testuser.xr1001.com',
    // mchUrl:'http://testmch.xr1001.com',
    // appId:'wx7a3026a810777b78',
    // haokuai test
    // userUrl:'http://jbs.hooook.com/cilent',
    // mchUrl:'http://jbs.hooook.com/merchant',
    // appId: 'wx543c2ef3ffdc6a51',
    // qianyi prod
    userUrl:'http://user.xr1001.cn',
    mchUrl:'http://mch.xr1001.cn',
    appId:'wx2f0ebe6c682ba94c',
    // qianyi Operation
    // userUrl:'http://user.xr1001.com',
    // mchUrl:'http://mch.xr1001.com',
    // appId:'wxb0d6d8ba8ae12322',
    // prod
    // userUrl:'http://user.xr1001.com',
    // mchUrl:'http://mch.xr1001.com',
    // appId:'wx9a85c8dab5fd8148',
  }

  export default config
