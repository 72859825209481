<style lang="less" scoped>
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
.action {
    font-size: 14px;
    color: #1989fa;
    font-weight: normal;
}
main {
  font-size: 12px;
  background: #fff;
} 
.tips {
  padding: 10px 15px;
  font-size: 12px;
  color: #999;
}
footer{
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    padding:15px;
}
</style>
<template>
  <div>
    <van-nav-bar
      @click-left="onClickLeft"
    >
      <template #title>
        <div class="title">数字编号</div>
      </template>
      <template #left>
        <van-icon name="arrow-left" color="#323233" size="20" />
      </template>
    </van-nav-bar>
    <main>
      <van-field
        label-width="200"
        v-model="physicalNumber"
        :disabled="true"
        label="AR眼镜物理编号"
      />
      <van-field
        v-model="num"
        label-width="200"
        required
        label="数字编号"
        placeholder="请输入"
      />
    </main>
    <footer>
        <van-button @click="submit" type="primary" size="normal" block>保存</van-button>
    </footer>
  </div>
</template> 
<script>
import { reactive, toRefs, ref } from '@vue/reactivity' 
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { updateGlass } from '@request/api'
import { Notify } from 'vant'
import store from '@/store'
export default {
  setup(){ 
    const type = ref('ADD')
    const router = useRouter()
    const data =  reactive({
      isInput: false
    })
    const formData = reactive({
      id: '',
      num: undefined,
      physicalNumber: undefined,
      storeId: localStorage.getItem('storeId')
    })
    const onClickLeft = () => {
        router.back()
    }
    const submit = () => { 
      if(!formData.num){
          Notify({
            type: 'warning',
            message: '请填写数字编号！'
          })
          return
      }
      if (formData.id) {
        updateGlass(formData).then(res => {
            if (res.success) { 
              store.commit('app/setGlasses', null)
              Notify({ type: 'success', message: '修改成功！' })
              router.back()
            }else{
              Notify({ type: 'warning', message: res.msg })
            }
        })
      } 
    }
    onMounted(() => {
      if (store.state.app.glasses) {
        type.value = 'EDIT'
        let glasses = store.state.app.glasses
        formData.id = glasses.id
        formData.num = glasses.num
        formData.physicalNumber = glasses.physicalNumber
      } else {
        type.value = 'ADD'
      }
    })
    return {
      type,
      onClickLeft,
      submit,
      ...toRefs(data),
      ...toRefs(formData)
    }
  }
}
</script>