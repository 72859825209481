<template>
  <div class="container">
    <van-nav-bar
      title="切换店铺"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="home-page">
      <header>轻触以切换店铺</header>
      <template v-if="storeList && storeList.length">
        <main>
          <van-pull-refresh v-model="loading" @refresh="onRefresh">
            <van-list
              v-model:loading="loading"
              :finished="finished"
              v-model:error="error"
              error-text="请求失败，点击重新加载"
              finished-text="没有更多了"
            >
            <div class="item-wrap" v-for="(item, index) in storeList" :key="index" @click="itemClick(item)">
              <div class="now" v-if="storeId == item.storeId">当前</div>
              <div class="left-wrap">
                <div class="title">{{item.venueName}}{{item.storeName ? `-${item.storeName}` : ''}}</div>
                <div class="user">
                  <img v-if="item.headImg" :src="item.headImg" alt="">
                  <img v-else src="@/assets/home/default.png" alt="">
                  <div v-if="item.userType == 1">管理员</div>
                  <div v-else>店员</div>
                </div>
              </div>
            </div>
            </van-list>
          </van-pull-refresh>
        </main>
      </template>
      <van-empty v-else image="error" description="您还未绑定门店，请先扫码绑定门店！" />
    </div>
  </div>
</template>
<script>
import {  ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { queryPageStore } from '@request/api'

// import { Dialog } from 'vant'
import store from '@/store'
export default {
  setup(){
    const onClickLeft = () => history.back()
    const loading = ref(false)
    const error = ref(false)
    const finished = ref(false)
    const storeList = ref(null)
    const router = useRouter()
    const storeId = ref(localStorage.getItem('storeId'))
    const queryPageStoreFun = () => {
      queryPageStore({})
      .then(
        res => {
        if (res.success) {
          storeList.value = res.data.records
          loading.value = false
          finished.value = true
        }
      }).catch(() => {
        error.value = true
      })
    }
    queryPageStoreFun()
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true
      queryPageStoreFun()
    }
    const itemClick = (item) => {
      store.commit('app/setStoreId', item.storeId)
      localStorage.setItem('storeId', item.storeId)
      // router.push(`/`)
      router.push({ path: `/`, query:{ status:'1'}})

    }

    return {
      storeList,
      loading,
      finished,
      queryPageStoreFun,
      error,
      onRefresh,
      itemClick,
      storeId,
      // fn
      onClickLeft
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  background: #EEEEEE;
}
.home-page {
  height: 100vh;
  padding: 30px 16px;
  background: #EEEEEE;
}
header {
  padding-top: 88px;
  padding-bottom: 48px;
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
  line-height: 33px;
}
main {
}

.item-wrap {
  position: relative;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
}
.now {
  position: absolute;
  top: 0px;
  right: 0;
  width: 46px;
  height: 24px;
  background: rgba(32,178,224, .2);
  text-align: center;
  border-radius: 0px 8px 0px 8px;
  line-height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #20B2E0;
}
.left-wrap {
  .title {
    margin-bottom: 8px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 25px;
    text-align: left;
  }
  .user{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    line-height: 18px;
    text-align: left;
    img {
      width: 40px;
      width: 40px;
      margin-right: 5px;
      border-radius: 100%;
    }
  }
}
</style>
