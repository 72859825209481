<style lang="less" scoped>
.container{
  height: 100vh;
  background: #fff;
  /deep/ .van-dialog__header {
    padding: 0;
  }
  /deep/ .van-field__control {
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
  /deep/ input::-webkit-input-placeholder { /* WebKit browsers */
    font-size:24px;
}
}
header {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  background: url('./img/account-bg.png');
  box-sizing: border-box;
  /deep/ .van-nav-bar {
    background:transparent;
  }
  /deep/ .van-hairline--bottom:after {
    border: none;
  }
}
main {
  .acc-title {
    padding: 0 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.4);
    line-height: 20px;
  }
  .acc-content {
    margin-left: 16px;
    height: 67px;
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 67px;
    border-bottom: 1px solid #eee;
  }
  /deep/ .van-cell {
    background: transparent;
  }
  /deep/ .van-cell__right-icon {
    color: #fff;
    font-size: 24px;
  }
  .link {
    height: 40px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;;
    line-height: 40px;
  }
}
footer{
  margin-top: 8px;
  padding: 16px;
  /deep/ .van-button {
    border-radius: 8px;
  }
  /deep/ .van-button__text {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;

  }
  .last {
    margin-top: 20px;
    border: 1px solid #1D1DD8;
    /deep/ .van-button__text {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #1D1DD8;
    }
  }
}
.dia-header {
  padding: 20px;
  height:108px;
  background: #E2E2E2;
  box-sizing: border-box;
  .close {
    text-align: right;
  }
  .dia-title {
    text-align: left;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    line-height: 33px;
  }
}
.dia-footer {
  padding: 16px 16px 32px;
  /deep/ .van-button {
    border-radius: 8px;
  }
  /deep/ .van-button__text {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.amount-box {
  border-bottom: 1px solid #808080;
  margin: 16px;
}
</style>
<template>
  <div class="container">
    <header>
      <van-nav-bar
        @click-left="onClickLeft"
      >
        <template #left>
          <van-icon name="arrow-left" color="#fff" :size="32" />
        </template>
      </van-nav-bar>
      <main v-if="accout">
        <div class="acc-title">账户余额（元）</div>
        <div class="acc-content">{{accout.amount}}</div>
        <van-cell @click="goCapitalFlow" center is-link >
          <template #title>
            <div class="link">查看自己流水明细</div>
          </template>
        </van-cell>
      </main>
    </header>
    <footer>
        <van-button :disabled="accout && accout.cooperativeState!==0" @click="show = true" color="#1D1DD8" size="large" block>充值</van-button>
        <van-button @click="withdrawal" class="last" size="large" plain  block>提现</van-button>
    </footer>
    <van-dialog
      v-model:show="show"
      confirmButtonColor="#1989fa"
      @cancel="cancel"
      :before-close="closeBefore"
      title="充值" show-cancel-button>
      <template #title>
        <div class="dia-header">
          <div class="close" @click="cancel"><van-icon name="cross" color="#787878" :size="36"/></div>
          <div class="dia-title">余额充值</div>
        </div>
      </template>
      <div class="amount-box">
        <van-field v-model="amount" type="number" placeholder="请输入充值金额" />
      </div>
      <template #footer>
        <div class="dia-footer">
          <van-button @click="payOrder" color="#1D1DD8" size="large">充值</van-button>
        </div>
      </template>
    </van-dialog>
  </div>
</template>
<script>
import { ref, reactive, toRefs } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { getAmount, generateWxPrePayOrder } from '@request/api'
// import store from '@/store'
import { Notify } from 'vant'
import config from '@/config'

export default {
  components:{
  },
  setup(){
    const formData = reactive({
      show: false,
      amount: null
    })
    const checked = ref([]);
    const checkboxGroup = ref(null);

    const checkAll = () => {
      checkboxGroup.value.toggleAll(true);
    }
    const toggleAll = () => {
      checkboxGroup.value.toggleAll();
    }

    const router = useRouter()
    const onClickLeft = () => {
        router.back()
    }
    const goCapitalFlow = () => {
      router.push(`/capitalFlow`)
    }
    const onBridgeReady = (params) => {
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
              "appId": config.appId,     //公众号ID，由商户传入
              "timeStamp": params.timestamp,         //时间戳，自1970年以来的秒数
              "nonceStr": params.noncestr, //随机串
              "package": `prepay_id=${params.prepayid}`,
              "signType":"MD5",         //微信签名方式：
              "paySign": params.sign //微信签名
          },
          (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            Notify({ type: 'success', message: '充值成功！'})
            formData.show = false
            getAmountFun()
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
      })
    }
    const cancel = () => {
      formData.show = false
      formData.amount = null
    }
    const closeBefore = (action) => {
      if (action === 'confirm') {
        if (formData.show) {
          return false
        } else {
          formData.amount = null
          return true
        }
      } else {
          formData.amount = null
        return true
      }
    }
    const payOrder = () => {
      if (formData.amount) {
        //验证金额（含零），保留两位小数
        const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
        if (!reg.test(formData.amount)){
          Notify({ type: 'warning', message: '请输入正确金额！' })
          return
        }
      } else {
          Notify({ type: 'warning', message: '请输入充值金额！' })
          return
      }

      generateWxPrePayOrder({
        storeId: localStorage.getItem('storeId'),
        openId: localStorage.getItem('openId'),
        amount: parseFloat(formData.amount)*100,
        remark: '备注'
      }).then((res) => {
        if (res.code) {
          if (typeof window.WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          }else{
            onBridgeReady(res.data)
          }
        }
      })
    }
    const accout = ref(null)
    const getAmountFun = () => {
      if(localStorage.getItem('storeId')){
        getAmount(localStorage.getItem('storeId')).then(res => {
          if (res.success) {
            accout.value = res.data
          }
        })
      }
    }
    const withdrawal = () => {
      router.push('/withdrawal')
    }
    onMounted(() => {
      console.log(123)
      console.log(accout.value)
      getAmountFun()
    })
    return {
      // data
      ...toRefs(formData),
      goCapitalFlow,
      accout,
      // func
      onClickLeft,
      payOrder,
      cancel,
      closeBefore,
      checked,
      checkAll,
      toggleAll,
      checkboxGroup,
      withdrawal,
      getAmountFun
    }
  }
}
</script>
