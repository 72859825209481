<template>
  <div class="store-page">
    <van-nav-bar
      @click-left="onClickLeft"
    >
      <template #left>
        <van-icon name="arrow-left" color="#D9D9D9" size="22" />
        <span class="back-title">选择门店</span>
      </template>
    </van-nav-bar>
    <!-- <div class="store-bg">
      <img src="./img/store-bg-on.png" alt="">
    </div> -->
    <main>
      <div class="title-box">
        <div class="title">门店管理</div>
        <div class="tip ellipsis">{{venueName}}{{storeName ? `-${storeName}` : ''}}</div>
      </div>
      <header>
          <div class="header-scan">
            <img src="./img/scan.png" alt="">
            <span class="scan-title">展示给玩家扫描选角色</span>
          </div>
          <div class="QR-code" v-if="qrCodeUrl">
              <img :src="qrCodeUrl" alt="">
              <!-- <qrcode-vue :value="qrCodeUrl" :size="160" level="H" /> -->
              <div class="QR-title">展示给用户扫描</div>
          </div>
      </header>
      <div class="nav">
        <van-cell v-if="userType == 1" url="#/accountManagement" is-link>
          <div class="left-warp">
            <img src="./img/user.png" alt=""> <span>子账号管理</span>
          </div>
        </van-cell>
        <van-cell v-for="(item, index) in girdList"  @click="navTo(item)" :key="index" is-link>
          <template #title>
            <div class="left-warp">
              <img :src="item.icon" alt=""> <span>{{item.menuName}}</span>
            </div>
          </template>
        </van-cell>
      </div>
    </main>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted  } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { queryByLogin, generateQrcodeByParam } from '@request/api'
import { Notify } from 'vant'
// import QrcodeVue from 'qrcode.vue'
// import store from '@/store'
// import { getConfigUrl } from '@/utils/configUrl.js'
export default {
  //导入组件
  components: {
    // QrcodeVue
  },
  setup(){
    const columns = [{name:'护照',value:'0'},{name:'身份证',value:'1'},{name:'港澳通行证',value:'2'}];
    const customFieldName = {
      text:'name'
    };
    const qrCodeUrl = ref(null)
    const route = useRoute()
    const userType = ref(null)
    // 玩家物料二维码
    // qrCodeUrl.value = getConfigUrl('user')+`?storeId=${localStorage.getItem('storeId')}`
    const router = useRouter()
    const storeName = ref(route.query.storeName)
    const venueName = ref(route.query.venueName)
    const girdList = ref([])
    const showPicker = ref(false);

    const data =  reactive({
      isInput: false
    })
    const formData = reactive({
      name: undefined,
      identityCard: undefined,
      mobile: undefined,
      cardType: '1'
      // comment: undefined
    })
    const navTo = (item) => {
      let url = ''
      switch(item.menuName) {
        case '账户管理':
          url = `/open`
          break
        case '角色/眼镜绑定':
          url = `/glasses?storeId=${localStorage.getItem('storeId')}`
          break
        case '剧本杀发车管理':
          url = `/activityManagement`
          break
        case '开本数据统计':
          url = `/statistics`
          break
      }
      router.push(url)
    }
    const onClickLeft = () => {
      router.back()
    }
    // 获取菜单权限
    const getAuth = () => {
      queryByLogin({storeId: localStorage.getItem('storeId')}).then( res => {
        if (res.success) {
          userType.value = res.data.userType
          res.data.menuList.forEach(item => {
            if (item.menuName == '账户管理') {
              item.icon = require('./img/account.png')
            }
            if (item.menuName == '开本数据统计') {
              item.icon = require('./img/Statistics.png')
            }
            if (item.menuName == '角色/眼镜绑定') {
              item.icon = require('./img/glasses.png')
            }
            if (item.menuName == '剧本杀发车管理') {
              item.icon = require('./img/activity.png')
            }
          })
          girdList.value = res.data.menuList
        } else {
          router.replace('/')
        }
      }).catch(err => {
         Notify({ type: 'danger', message: err })
      })
    }
    // 获取门店二维码
    const generateQrcodeByParamFunc = () => {
      if(localStorage.getItem('storeId')){
        generateQrcodeByParam({storeId: localStorage.getItem('storeId')}).then((res) => {
          if (res.success) {
            // 玩家物料二维码
            qrCodeUrl.value = res.data
          }
          console.log(res)
        })
      }

    }
    generateQrcodeByParamFunc()
    onMounted(() => {
      getAuth()
    })
    return {
      onClickLeft,
      girdList,
      navTo,
      ...toRefs(data),
      ...toRefs(formData),
      columns,
      customFieldName,
      showPicker,
      storeName,
      venueName,
      qrCodeUrl,
      userType
    }
  }
}
</script>
<style lang="less" scoped>
.store-page {
  position: relative;
  background: url('./img/store-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  /deep/ .van-nav-bar {
    background: none;
  }
  /deep/ .van-hairline--bottom:after {
    display: none;
  }
  .store-bg {
    position: absolute;
    left: 44px;
    top: 20px;
  }
  .back-title {
    margin-left: 4px;
    color: rgb(217, 217, 217);
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: undefined;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
}
main {
  position: absolute;
  z-index: 1000;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 20px;
  overflow: auto;
}
.title-box {
  .title {
    color: rgb(255, 255, 255);
    font-family: PingFang SC;
    font-size: 32px;
    font-weight: undefined;
    line-height: 47px;
    letter-spacing: 0px;
    text-align: left;
  }
  .tip {
    color: rgba(255, 255, 255, 0.4);
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: undefined;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}
.title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
}
header {
  margin-top: 20px;
  height: 320px;
  background: #fff;
  border-radius: 16px;
  .header-scan {
    height: 56px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: #EEEEEE;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    img {
      width: 21px;
      height: 21px;
    }
    .scan-title {
      margin-left: 8px;
      color: rgb(29, 29, 216);
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: undefined;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}
.header-title, .item-title {
    font-size: 14px;
    font-weight: bold;
    color: #666;
}
.QR-code {
    margin-top: 5px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    .QR-title {
      color: rgba(0, 0, 0, 0.25);
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: undefined;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
    }
    img{
        width: 210px;
        height: 210px;

    }
}
.nav {
  margin-top: 20px;
  padding: 8px;
  border-radius: 16px;
  background: #fff;
}
.left-warp {
  display: flex;
  align-items: center;
}
</style>
