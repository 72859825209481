<template>
  <van-dialog
    v-model:show="show"
    confirmButtonColor="#1989fa"
    @cancel="cancel"
    :before-close="closeBefore"
    title="充值" show-cancel-button>
      <template #title>
        <div class="dia-header">
          <div class="close" @click="cancel"><van-icon name="cross" color="#787878" :size="36"/></div>
          <div class="dia-title">余额充值</div>
        </div>
      </template>
      <div class="amount-box"> 
        <van-field v-model="amount" type="number" placeholder="请输入充值金额" />
      </div>
      <template #footer>
        <div class="dia-footer"> 
          <van-button @click="payOrder" color="#1D1DD8" size="large">充值</van-button>
        </div>
      </template>   
    </van-dialog>
</template>
<script>
import { reactive, toRefs } from '@vue/reactivity' 
import { Notify } from 'vant'
import config from '@/config'
import { generateWxPrePayOrder } from '@request/api'
export default {
  setup(props, { emit }) {
    console.log(props)
    const formData = reactive({
      show: props.show,
      amount: null
    })
    const onBridgeReady = (params) => {
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
              "appId": config.appId,     //公众号ID，由商户传入     
              "timeStamp": params.timestamp,         //时间戳，自1970年以来的秒数     
              "nonceStr": params.noncestr, //随机串     
              "package": `prepay_id=${params.prepayid}`,     
              "signType":"MD5",         //微信签名方式：     
              "paySign": params.sign //微信签名  
          },
          (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            Notify({ type: 'success', message: '充值成功！'})
            emit('cancel')
            emit('get-amount-fun')
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } 
      })
    }
    const cancel = () => {
      emit('cancel')
      formData.amount = null 
    }
    const closeBefore = (action) => {
      console.log(action)
      if (action === 'confirm') {
        if (formData.show) {
          return false
        } else {
          formData.amount = null
          return true
        }
      } else {
          formData.amount = null
        return true
      }
    }
    const payOrder = () => { 
      if (formData.amount) { 
        //验证金额（含零），保留两位小数
        const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
        if (!reg.test(formData.amount)){
          Notify({ type: 'warning', message: '请输入正确金额！' })
          return
        } 
      } else { 
          Notify({ type: 'warning', message: '请输入充值金额！' })
          return
      }
      
      generateWxPrePayOrder({
        storeId: localStorage.getItem('storeId'),
        openId: localStorage.getItem('openId'),
        amount: parseFloat(formData.amount)*100,
        remark: '备注'
      }).then((res) => {
        if (res.code) {
          if (typeof window.WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          }else{
            onBridgeReady(res.data)
          }
        }
      })
    }
    return {
      // data
      ...toRefs(formData),
      // func
      payOrder,
      cancel,
      closeBefore
    }
  }
}
</script>
<style lang="less" scoped>
.dia-header {
  padding: 20px;
  height:108px;
  background: #E2E2E2; 
  box-sizing: border-box;
  .close {
    text-align: right;
  } 
  .dia-title {
    text-align: left;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    line-height: 33px;
  }
}
.dia-footer {
  padding: 16px 16px 32px;
  /deep/ .van-button {
    border-radius: 8px;
  }
  /deep/ .van-button__text { 
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.amount-box {
  border-bottom: 1px solid #808080;
  margin: 16px;
}
</style>
